import React, { useContext, useEffect, useState } from "react";
import { apiConst, appConst } from '../../../services/constant/constant';
import { serverGet, serverPost, serverFormPost, serverAPIPost } from '../../../middleware/http'
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Signup from "./SignUp";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import AccountActivation from "./AccountActivation";
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { formValidation, getAuthProfile, getMsg, getSearchParams, isEnabled } from '../../../services/utils/validation';

const PubSecurityLayout = (props) => {

    const { isGWF } = useAccountSettings();
    const clientId = getSearchParams("client_id");
    const clientSecret = getSearchParams("client_secret");
    const scopes = getSearchParams("scope");
    const redirect_uri = getSearchParams("redirect_uri");
    const response_type = getSearchParams("response_type");
    const to_redirect_uri = getSearchParams("to_redirect_uri");
    const [ authChecking, setauthChecking ] = useState(null);
    const { progParams } = useContext(ProgressContext);
    useEffect(() => {
        progParams(true);
        
        (async () => {
            try {
                const response = await serverGet(apiConst.getauthprofile);

                if (response?.data?.auth) {

                    if (clientId != null && typeof clientId != "undefined") {
                        progParams(true);
                        let frmData = new FormData();
                        frmData.append("client_id", clientId);
                        frmData.append("client_secret", clientSecret);
                        frmData.append("grant_type", "client_credentials");
                        //axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                        try {
                            await serverFormPost(`${apiConst.authorizeGet}`, frmData, false)
                                .then(res => {
                                    //console.log(res.access_token)
                                    return window.location.href = `${redirect_uri}&code=${res.access_token}&state=b3BlbmlkY29ubmVjdA%3D%3D&to_redirect_uri=${to_redirect_uri}`;//&iss=https%3A%2F%2Flocalhost%3A44324%2F

                                });

                        } catch (err) { alert(err) }
                    } else {
                        return window.location.href = "/dashboard";
                    }

                } else {
                   
                    setauthChecking(true); progParams(false); 
                }

            } catch (err) {
                //progParams(false); 
            }
        })();
    }, []);

    return (
        <>{authChecking != null ?
            <div className="card border-0">
                <div className="card-body p-2 p-md-4">
                    <div className="row align-self-center justify-content-center">
                        {isGWF &&
                            <div className="col-md-5 col-lg-6 align-self-center my-4 d-none d-md-block">
                                <img className="img-fluid" src={require('../../../asset/images/gayawellness-banner.jpg')} alt="banner-image" />
                            </div>
                        }
                        <div className="col-md-7 col-lg-6">
                            <div className="d-flex justify-content-center align-items-center rounded" style={{ minHeight: "78.5vh" }}>
                                <div className="flex-fill">
                                    {(() => {
                                        switch (props.body) {
                                            case 'forgotpassword': return (<ForgotPassword />)
                                            case 'resetpassword': return (<ResetPassword />)
                                            case 'signup': return (<Signup />)
                                            case 'accountactivation': return (<AccountActivation />)
                                            default: return (<Login />)
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>:<></>}
        </> 
    );
}

export default PubSecurityLayout;