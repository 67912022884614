import React from 'react'
import ActionStatus from '../../../../services/utils/actionStatus';
import { dateTimeFormat, updateSearchParams } from '../../../../services/utils/validation';
import { useAccountSettings } from '../../../../services/Context/AccSettingContext';

export default function FormList({ toggeleForm, setSelectedFormId, formsList, level }) {
    const { accountDate } = useAccountSettings();

    const selectForm = (id) => {
        updateSearchParams("frmid", id);
        setSelectedFormId(id);
        toggeleForm();
    }

    return (
        <>
            <div>
                {(Array.isArray(formsList) && formsList?.length > 0) && (
                    <div className="mb-5" id="dViewMedication">
                        <div className="row d-none d-md-flex text-secondary">
                            <div className="col">
                                <div className="row">
                                    <div className="col">Name</div>
                                    {level == "patient-forms" && (
                                        <>
                                            <div className="col">Modified by</div>
                                            <div className="col">Modified on</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div><hr />
                        {formsList?.map((list, i) => (
                            <>
                                <div className="row align-items-center" key={i}>
                                    <div className="col">
                                        <span className="pointer-class" onClick={() => selectForm(list?.formId)}>{list?.clientTitle}</span>
                                    </div>
                                    {level == "patient-forms" && (
                                        <>
                                            {list?.modifiedBy && (
                                                <div className="col">
                                                    <span >{list?.modifiedBy}</span>
                                                </div>
                                            )}
                                            {list?.modifiedOn && (
                                                <div className="col">
                                                    <span>{dateTimeFormat(list?.modifiedOn, accountDate?.value)}</span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <hr className="m-2" />
                            </>
                        ))}
                    </div>
                )}
                {((!formsList || formsList?.length == 0) && (formsList !== "loading")) && (
                    <div>
                        <ActionStatus description={"No records found"} button={""} imageid="#svg_failed" onsyncchange={""} type="search" />
                    </div>
                )}
            </div>

        </>
    )
}
