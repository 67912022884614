import React, { useState } from "react"
import FamilyMemberAdd from "./FamilyMemberAdd"
import FamilyMemberList from "./FamilyMemberList"
import { useManageResource } from "../../../../services/Context/ManageResourceContext";
import { useEffect } from "react";
import { resourceConst } from "../../../../services/constant/constant";
import { useLanguageRes } from "../../../../services/Context/LanguageContext";


export default function FamilyMember() {
    const [resourceAction, setResourceAction] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedFamilyMember, setSelectedFamilyMember] = useState(null);

    const { resourceList, getCurrentResourceAction, actionExists } = useManageResource();
    const { getLanguageContent } = useLanguageRes();

    const toggle = (list) => {
        if (list) {
            setSelectedFamilyMember(list);
        }

        setShowAdd(!showAdd);
    }



    useEffect(() => {

        const _action = getCurrentResourceAction("SK6")
        setResourceAction(_action);

    }, [resourceList])




    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <h4>{showAdd && selectedFamilyMember ? "Edit family member" : showAdd ? getLanguageContent("fmbadd") : getLanguageContent("fmbmem")}</h4>
                </div>
                {!showAdd && (
                    <div>
                        {(actionExists(resourceAction, resourceConst?.actions?.create)) && (
                            <button onClick={() => {
                                setSelectedFamilyMember(null);
                                toggle();
                            }} className="btn btn-primary">{getLanguageContent("fmbnew")}</button>
                        )}
                    </div>
                )}
            </div>
            <div className="card border-0">
                <div className="card-body">
                    <div>
                        {!showAdd && <FamilyMemberList toggle={toggle} />}
                        {showAdd && <FamilyMemberAdd selectedFamilyMember={selectedFamilyMember} toggle={toggle} isCreate={actionExists(resourceAction, resourceConst?.actions?.create)} />}
                    </div>
                </div>
            </div>
        </>
    )
}