import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import he from "he"; 


const Footer = ({ account }) => {


    const [footer, setFooter] = useState(null);


    useEffect(() => {

        if (Array.isArray(account?.portalSettings)) {

            const data = account?.portalSettings?.find((list) => list?.code == "FTC");
            if (data) {
                const cleanHtml = DOMPurify.sanitize(data?.value);
                const decodedHTML = he.decode(cleanHtml);
                //console.log(cleanHtml);
                setFooter(decodedHTML)
            }
               

        }

    }, [account]);

   
   // console.log(footer);
    return (
        <>
            <footer className="footer-container text-white">
                {/*<div className="row">*/}
                {/*    <div className="col-md-3 col-xxl-2">*/}
                {/*        <p className="text-white">© {new Date().getFullYear()} Powered by <a href="https://www.drcare247.com" target="blank" className="text-white">DrCare247</a></p>*/}
                {/*    </div>*/}

                {/*    <div className="col-md-9  col-xxl-10" dangerouslySetInnerHTML={{ __html: footer?.value }}>*/}

                {/*    </div>*/}

                {/*</div>*/}

                <div className="d-flex flex-column flex-md-row ">
                    <div className="flex-shrink-0">
                        <div className="text-white">© {new Date().getFullYear()} Powered by <a href="https://www.drcare247.com" target="blank" className="text-white">DrCare247</a></div>
                    </div>
                    {footer && (
                        <div className="flex-grow-1 ms-2" dangerouslySetInnerHTML={{ __html: footer }}>
                        </div>
                    )}              
                </div>

                {/*<ul className="col-md-4 justify-content-end list-unstyled d-flex ">*/}
                {/*    <li className="ms-3"><a className='text-white' href="#">F</a></li>*/}
                {/*    <li className="ms-3"><a className='text-white' href="#">T</a></li>*/}
                {/*    <li className="ms-3"><a className='text-white' href="#">W</a></li>*/}
                {/*</ul>*/}
            </footer>
        </>
    );
}

export default Footer;