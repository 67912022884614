import React from "react";
import Insurance from "../insurance/Insurance";


export default function PatientInsurance() {
    return (
        <>
            <Insurance code={"SQ4"} />
        </>
    )
}