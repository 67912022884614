import React, { useContext, useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { serverGet, serverPost } from "../../../middleware/http";
import { apiConst, appConst } from "../../../services/constant/constant";
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { ecnSession, formValidation, getMsg } from "../../../services/utils/validation";
import DocumentUpload from "../../fileUpload/DocUpload";
import { useLanguageRes } from '../../../services/Context/LanguageContext';
export default function DocumentAdd(props) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [documents, setDocuments] = useState([]);
    const [cateogry, setCateogry] = useState([]);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const member_id = localStorage.getItem("memberId");
    const { getLanguageContent } = useLanguageRes();

    const getCateogry = () => {
        progParams(true);

        serverGet(`${apiConst.getcateogry}${props?.cateogries}`).then((res) => {
            progParams(false);
            try {
                setCateogry(res.data);
            } catch (e) {
                console.log(e)
            }
        })
    }

    const onSubmit = (data) => {
        const sessionStore = ecnSession();
        const enc_id = urlSearchParams.get('eid');
        const fmid = urlSearchParams.get('fmid');
        const sizeInBytes = 10 * 1024 * 1024
        let size_validation = false;
        let type_validation = false;
        let name_validation = false;


        if (documents.length > 0) {
            documents.forEach((item) => {
                const doc = item.src;
                if (doc.size > sizeInBytes && !formValidation.filename_val.test(doc.name) && !props.fileTypes?.includes(doc.type)) {
                    size_validation = true;
                    type_validation = true;
                    name_validation = true;
                } else if (!appConst.fileTypes.encounter.validationTypes?.includes(doc.type)) {
                    type_validation = true;
                } else if (doc.size > sizeInBytes) {
                    size_validation = true;
                } else if (!formValidation.filename_val.test(doc.name)) {
                    name_validation = true;
                }
            })
        }


        const document_files = [];
        if (sessionStore && sessionStore?.uploadid && sessionStore?.uploadid?.length > 0) {
            for (const doc of sessionStore?.uploadid) {
                if (enc_id == doc?.encId) {
                    document_files.push({
                        memberId: fmid ? fmid :member_id,
                        recordTypeId: props?.recordTypeId,
                        fileName: doc?.name,
                        filePath: doc?.src,
                        recordId: props?.recordId,
                        note: data?.description,
                        typeId: data?.cateogry
                    })
                }
            }
        }

        if (document_files.length <= 0 && documents?.length <= 0) {
            notifyToast(332);
        } else if (size_validation && type_validation) {
            notifyToast(333);
        } else if (type_validation) {
            notifyToast(330);
        } else if (size_validation) {
            notifyToast(331);
        } else if (name_validation) {
            notifyToast(337);
        } else {
            progParams(true);

            serverPost(apiConst.docupdate, document_files).then((res) => {
                progParams(false);
                try {
                    if (res?.status == 1) {
                        notifyToast(res.messageCode[0]);
                        const enc_documents = sessionStore?.uploadid?.filter(item => item.encId !== enc_id);
                        ecnSession(enc_documents, "uploadid");
                        props?.toggleForm();
                        //getDocuments();
                        //setShowList(false);
                    }
                    else {
                        var error = JSON.parse(res.data.errorMessage);
                        notifyToast(error.messageCode[0]);
                    }
                } catch (e) {
                    console.log(e);
                }
            })
        }
    }

    const handleBack = (e) => {
        e.preventDefault();
        props?.toggleForm();
    }
    useEffect(() => {
        getCateogry();
    }, [])
    return (
        <>
            {cateogry?.length > 0 &&

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="row">
                        <div className="col-xl-10">
                            <div className="mb-3">
                                <label htmlFor="cateogry" className="form-label">{getLanguageContent("ctgy")}<span className="text-danger star">*</span></label>
                                <select type="text" className="form-control form-select" id="cateogry" placeholder={getLanguageContent("ctgy")} {...register("cateogry", { required: getMsg(301) })}>
                                    <option value="">{getLanguageContent("select")}</option>
                                    {cateogry?.length > 0 && cateogry?.map((list, index) => (
                                        <option key={index} value={list?.id}>{list?.name}</option>
                                    ))}
                                </select>
                                {errors.cateogry?.message && <span className="text-danger">{errors.cateogry?.message}</span>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">{getLanguageContent("dscrpton")}</label>
                                <textarea placeholder={getLanguageContent("dscrpton")} className="form-control" id="description" rows="3" {...register("description", { maxLength: { value: 500, message: getMsg("305") } })}></textarea>
                                {errors.description?.message && <p className="text-danger">{errors.description?.message}</p>}
                            </div>
                            <div className="row">
                                <DocumentUpload size={10}
                                    setDocState={setDocuments}
                                    encounter={props?.encounter}
                                    fileTypes={props.fileTypes}
                                    disText={props.disText}
                                    name="profile-doc"
                                />
                            </div>
                            <div className="my-4 text-end">
                                <button type="submit" className="btn btn-lg btn-secondary me-2" onClick={(e) => handleBack(e)}>{getLanguageContent("bck")}</button>
                                <button type="submit" className="btn btn-lg btn-primary">{getLanguageContent("uplod")}</button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </>
    )
}