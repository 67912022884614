import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { serverGet } from '../../../middleware/http';
import { apiConst } from '../../../services/constant/constant';
import ProgressContext from '../../../services/utils/progress';
import { getSearchParams, updateSearchParams } from '../../../services/utils/validation';
import ScheduleHeader from '../consultation/summary/ScheduleHeader';
import { useLanguageRes } from '../../../services/Context/LanguageContext';

export default function MemberSpecialty({ seeAll, setSelectedSpecialty, selectedSpecialty, setList, setDefaultSpecialty, defaultSpecialty }) {

    const [specialityList, setSpecialityList] = useState([]);
    // const [specialityId, setSpecialityId] = useState("");
    const { progParams } = useContext(ProgressContext);
    const navigate = useNavigate();
    // const urlSearchParams = new URLSearchParams(window.location.search);
    const { getLanguageContent } = useLanguageRes();

    const getList = () => {
        const residing_Id = getSearchParams("rsid");
        progParams(true);
        const baseURL = apiConst.getproviderspeciality;
        const url = residing_Id ? `${baseURL}?sid=${residing_Id}` : baseURL;
        serverGet(url).then((res) => {
            progParams(false);
            try {
                if (res?.data && res?.data != null) {

                    if (Array.isArray(res?.data) && res?.data?.length > 0) {
                        const resData = [...res?.data];
                        const indexToRemove = resData?.findIndex(obj => obj.code === "OTHER");
                        if (indexToRemove !== -1) {
                            const dataToRemove = resData.splice(indexToRemove, 1)[0];
                            resData.push(dataToRemove);
                            setSpecialityList([...resData]);
                        } else {
                            setSpecialityList(resData);
                        }
                        setList(resData);
                        const defaultSpecialty = resData?.find((item) => item?.isDefault == true)?.id;
                        if (defaultSpecialty) setDefaultSpecialty(defaultSpecialty);
                        const specialtyId = getSearchParams("spid");
                        if (specialtyId && specialtyId?.trim()) {
                            const choosedSpecialty = resData?.find((list) => list.id == specialtyId)
                            if (choosedSpecialty) setSelectedSpecialty(choosedSpecialty?.id)
                            setSelectedSpecialty(specialtyId);
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })
    }
    //, { state: { id: specialityid } }

    const selectSpeciality = (specialityid) => {
        if (!seeAll) {
            updateSearchParams("spid", specialityid);
            setSelectedSpecialty(specialityid);
        }
        if (seeAll)
            navigate(`/provider?spid=${specialityid}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}`);
    }

    const text200 = "but also the leap into electronic typesetting, remaining essentially unchanged electronic typesetting, remaining essentially unchanged electronic typesetting, remaining essentially unchanged unchanged unchanged.";



    const svgIcon = {
        iconPath: `  <svg id="svg_GeneralMedicine" viewBox="0 0 128 128" height="60" width="60">
                <path d="M61.748,70.68H56.834V65.766a1.749,1.749,0,0,0-1.75-1.75H46.435a1.75,1.75,0,0,0-1.75,1.75V70.68H39.772a1.75,1.75,0,0,0-1.75,1.75v8.648a1.75,1.75,0,0,0,1.75,1.75h4.913v4.913a1.75,1.75,0,0,0,1.75,1.75h8.649a1.749,1.749,0,0,0,1.75-1.75V82.828h4.914a1.75,1.75,0,0,0,1.75-1.75V72.43A1.75,1.75,0,0,0,61.748,70.68ZM60,79.328H55.084a1.75,1.75,0,0,0-1.75,1.75v4.913H48.185V81.078a1.75,1.75,0,0,0-1.75-1.75H41.522V74.18h4.913a1.751,1.751,0,0,0,1.75-1.75V67.516h5.149V72.43a1.751,1.751,0,0,0,1.75,1.75H60Z" />
                <path d="M97.694,93.58H82.223V45.986a8.531,8.531,0,0,0-6.712-8.3l-1.93-.414A5.014,5.014,0,0,1,69.636,32.4V27.767h3.975a1.75,1.75,0,0,0,1.75-1.75V14.146a1.75,1.75,0,0,0-1.75-1.75h-45.7a1.751,1.751,0,0,0-1.75,1.75V26.017a1.751,1.751,0,0,0,1.75,1.75h3.975V32.4a5.013,5.013,0,0,1-3.944,4.877l-1.931.414a8.53,8.53,0,0,0-6.712,8.3v61.129a8.5,8.5,0,0,0,8.488,8.489H73.735a8.483,8.483,0,0,0,3.379-.712,10.919,10.919,0,0,0,3.847.708H97.694a11.01,11.01,0,0,0,0-22.02ZM22.8,59.924H78.723v33.66H22.8ZM29.658,15.9h42.2v8.371h-42.2ZM26.741,41.108l1.93-.413a8.529,8.529,0,0,0,6.712-8.3V27.767H66.136V32.4a8.53,8.53,0,0,0,6.713,8.3l1.929.413a5.016,5.016,0,0,1,3.945,4.878V56.424H22.8V45.986A5.015,5.015,0,0,1,26.741,41.108ZM22.8,107.115V97.084H72.934a10.942,10.942,0,0,0,0,15.02H27.784A4.994,4.994,0,0,1,22.8,107.115Zm50.655-2.525a7.528,7.528,0,0,1,7.51-7.51h6.622V112.1H80.961A7.519,7.519,0,0,1,73.451,104.59Zm29.563,5.311a7.5,7.5,0,0,1-5.32,2.2H91.083V97.08h6.611a7.517,7.517,0,0,1,5.32,12.821Z" />
            </svg>`
    }
    useEffect(() => {

        if (!seeAll)
            document.title = "Specialty";
        getList();
    }, [])


    return (
        <>
            <div className="w-100">
                {!seeAll ?
                    <>
                        <ScheduleHeader title={getLanguageContent("pckspeclization")} />
                        {defaultSpecialty && (
                            <div className="mb-3">
                                <small className="text-secondary ">{getLanguageContent("indspl")}</small>
                            </div>
                        )}
                    </>
                    :
                    <div className="d-flex justify-content-between align-items-center px-3 mt-2">
                        {seeAll && (
                            <>
                                <p className="fs-5">{getLanguageContent("pckspeclization")}</p>
                                <small className="text-secondary">{getLanguageContent("indspl")}</small>
                                <Link className="text-decoration-none" to="/specialty">
                                    <p className="fs-sm-6">{getLanguageContent("seall")} <span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                    </svg></span>
                                    </p>
                                </Link>
                            </>
                        )}
                    </div>
                }
                <div className="row g-2 g-md-3">
                    {specialityList?.map((list, index) => (
                        <div className="col-md-6 col-lg-4 col-xxl-3 rounded list-nav d-flex align-items-stretch" >
                            <div className={`card p-3 pointer-class text-muted w-100 ${!seeAll && selectedSpecialty == list?.id ? "theme-border specialty-svg-icons-selected" : "specialty-svg-icons"}`} onClick={() => selectSpeciality(list?.id)} key={index}>
                                <div className="d-flex align-items-stretch my-auto">
                                    <div className="me-1 my-auto">
                                        <svg width="70" height="70" role="img" dangerouslySetInnerHTML={{ __html: list?.iconPath == null || !list?.iconPath ? svgIcon?.iconPath : list?.iconPath }}>
                                            {/*    <use xlinkHref={`#${list?.iconPath == null || !list?.iconPath ? "svg_GeneralMedicine" : list?.iconPath}`} />*/}
                                        </svg>
                                    </div>
                                    <div className="text-truncate my-auto">
                                        <small className="fw-semibold">{list?.name}</small>

                                        {/*<small className="w-100 d-block text-wrap  text-wrap-custom"><TruncateText text={text200} /></small>*/}

                                        {list?.description && <small className="w-100 d-block text-wrap text-wrap-custom"><TruncateText text={list?.description} /></small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}




const TruncateText = ({ text, maxLength }) => {
    //const truncatedText = text.length > maxLength
    //    ? `${text.slice(0, maxLength)}...`
    //    : text;

    return <div className="text-wrap-custom">{text}</div>;
};