import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { serverGet } from '../../../../middleware/http';
import { apiConst, appConst, resourceConst } from '../../../../services/constant/constant';
//import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../../services/Context/LanguageContext';
import { useManageResource } from '../../../../services/Context/ManageResourceContext';
import ProgressContext from '../../../../services/utils/progress';
import { getSearchParams } from '../../../../services/utils/validation';
import CustomForm from './customForm';
import FormList from './FormList';

export default function Forms({ level, formShowState, encounter, tabData, areaId }) {
    const [showForm, setShowForm] = useState(formShowState || getSearchParams("frmid") ? true : false);
    const [selectedFormId, setSelectedFormId] = useState(null);
    const [resourceAction, setResourceAction] = useState([]);
    const [formsList, setFormsList] = useState([]);
    const { progParams } = useContext(ProgressContext);
    const { getLanguageContent } = useLanguageRes();
    const { resourceList, getCurrentResourceAction, actionExists } = useManageResource();




    const toggeleForm = () => {
        setShowForm((prev) => !prev);
    }



    useEffect(() => {
        const _action = getCurrentResourceAction(tabData?.code, tabData?.parentId);
        setResourceAction(_action);

    }, [resourceList])

    //console.log(resourceAction);

    //;

    const getForms = async () => {
        try {
            // progParams(true);
            //setTimeout(() => {
            //    progParams(true);

            //}, 200)
            let speciality_id = getSearchParams("spid") || encounter?.specialityId;
            let encounter_id = getSearchParams("eid");
            let fmid = getSearchParams("fmid");
            if (!speciality_id && encounter_id) {
                const res = await serverGet(apiConst.getencounter + encounter_id);
                if (res?.status == 1 && res.data?.specialityId)
                    speciality_id = res.data?.specialityId;
            }
            let member_id = localStorage.getItem("memberId");
            if (!member_id) {
                const patient_res = await serverGet(apiConst.patientinfo);
                if (patient_res?.status == 1 && patient_res?.data && patient_res?.data?.memberId)
                    member_id = patient_res?.data?.memberId;
            }
            setFormsList("loading");

            const url = (level == "encounter-forms" || level == "intake-flow") ? `${apiConst.formsList}${fmid ? fmid :member_id}?sid=${speciality_id}&areaid=${areaId}&eid=${encounter_id}` : `${apiConst.formsList}${member_id}?areaid=${areaId}`

            const res = await serverGet(url);
            if (res?.data != null && res.status == 1) {
                setFormsList(res?.data);
            } else {
                setFormsList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {

        }

    }


    useEffect(() => {
        setTimeout(() => {
            getForms();
        }, 400)
    }, [])

    //console.log(tabData);


    return (
        <>
            <div className={encounter ? "card border-0" : ""}>
                <div className={encounter ? "card-body" : ""}>
                    {level == "patient-forms" && (
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                                <h5 className={"fs-6 card-title  text-uppercase fw-bold"}>{getLanguageContent("frms")}</h5>
                            </div>
                        </div>
                    )}

                    <div className={encounter ? "" : "card border-0"}>
                        <div className={encounter ? "" : "card-body"}>
                            {!showForm && <FormList setSelectedFormId={setSelectedFormId} toggeleForm={toggeleForm} level={level} formsList={formsList} />}
                            {showForm && <CustomForm createAction={actionExists(resourceAction, resourceConst?.actions?.create)} selectedFormId={selectedFormId} setSelectedFormId={setSelectedFormId} toggeleForm={toggeleForm} level={level} formsList={formsList} encounter={encounter} tabData={tabData} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
