//Dashboard
//import Dashboard from "../components/modules/dashboard/Dashboard";
import Home from "../theme/default/Home";
//import ChangePassword from "../components/modules/security/ChangePassword";

//Security
import PubSecurityLayout from "../components/modules/security/PubSecurityLayout";
import MemberSpecialty from "../components/modules/speciality/MemberSpeciality";
import Booking from "../components/modules/booking/Booking";
//import Profile from "../components/modules/patient/profile";

import ProviderList from "../components/modules/provider/Providerlist";
import ProviderDetail from "../components/modules/provider/ProviderDetail";
//import DetailPage from "../components/modules/consultation/detaiLPage";
import PrintLayout from "../theme/Print/Layout";
import DashBoardLayout from "../components/modules/dashboard/DashboardLayout";
import Reason from "../components/modules/reason/Reason";
import ScheduleConfirmation from "../components/modules/consultation/summary/ScheduleConfirmation";
import Pharmacy from "../components/modules/pharmacy/pharmacy";
import Payment from "../components/modules/payments/payment";
//import Transaction from "../components/modules/transaction/transaction";
//import Forms from "../components/modules/form/forms";
//import FormView from "../components/modules/form/view";
import MessageHome from "../components/modules/message/home";
import AddProfile from "../components/modules/patient/AddProfile";
import ProofUpload from "../components/modules/patient/ProofUpload";
//import Proof from "../components/modules/patient/proofupload/proof";
import VitalAdd from "../components/modules/consultation/vital/vitalAdd";
import Insurance from "../components/modules/insurance/Insurance";
import PaymentProcess from "../components/modules/payments/PaymentProcees";
import PaymentFailed from "../components/modules/payments/PaymentFail";
import ProcessPayment from "../components/modules/payments/ProcessPayment";
import ResidingState from "../components/modules/patient/ResidingState";
import PageNotFound from "../services/utils/PageNotFound";
import PatientDocument from "../components/modules/patient/patientDocument";
import VitalsForms from "../components/modules/consultation/vital/VitalsForms";
import Specialty from "../components/modules/speciality/Specialty";
import IntakeForms from "../components/modules/consultation/form/FormLevel/IntakeForm";
import MedicationConsent from "../components/modules/consultation/MedicationConsent";
import TestDemoPage from "../components/fileUpload/DemoTestPage";
import PatientInsurance from "../components/modules/patient/PatientInsurance";
import EncounterInsurance from "../components/modules/consultation/EncounterInsurance";
import PatientForms from "../components/modules/consultation/form/FormLevel/PatientForms";
//import ResourceRoute from "../components/MasterRes";
import DocumentsViewer from "../components/modules/Documents/DocumentsViewer";
import EncounterSummary from "../components/modules/consultation/summary/EncounterSummary";
//import SummaryForm from "../components/modules/consultation/form/SummaryForm/SummaryForm";
import FamilyMember from "../components/modules/patient/FamilyMember/FamilyMember";
import SelectFamilyMember from "../components/modules/patient/FamilyMember/SelectFamilyMember";
import MemberPlanDetail from "../components/modules/MemberPlan/MemberPlanDetail";
import AppointmentMode from "../components/modules/dashboard/AppointmentMode";
import ExternalOrderCreate from "../components/modules/consultation/ExternalorderCreate";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    //{
    //    path: "/testresroute",
    //    element: <ResourceRoute />
    //},
    //{
    //    path: "/testresroute",
    //    element: <SummaryForm />
    //},
    {
        path: "/security/login",
        element: <PubSecurityLayout body="login" />
    },
    {
        path: "/security/signup",
        element: <PubSecurityLayout body="signup" />
    },
    {
        path: "/security/forgotpassword",
        element: <PubSecurityLayout body="forgotpassword" />
    },
    {
        path: "/security/resetpassword",
        element: <PubSecurityLayout body="resetpassword" />
    },
    {
        path: "/activateaccount",
        element: <PubSecurityLayout body="accountactivation" />
    },
    {
        path: "/insurance",
        element: <PatientInsurance />
    },
    {
        path: "/alldocuments",
        element: <PatientDocument />
    },
    {
        path: "/payment/insurance",
        element: <EncounterInsurance />
    },
    //{
    //    path: "/transaction",
    //    element: <Transaction />
    //},
    //{
    //    path: "/module/forms",
    //    element: <Forms />
    //},
    {
        path: "/vitals",
        element: <VitalAdd />
    },
    {
        path: "/confirmation",
        element: <ScheduleConfirmation />
    },
    {
        path: "/dashboard",
        element: <DashBoardLayout body="dashboard" />
    },
    {
        path: "/profile",
        element: <DashBoardLayout body="profile" />
    },
    {
        path: "/residingstate",
        element: <ResidingState />
    },
    //{
    //    path: "/profile/idinfo",
    //    element: <Proof />
    //},   
    {
        path: "/idverification/upload",
        element: <ProofUpload />
    },
    {
        path: "/addprofile",
        element: <AddProfile />
    },
    {
        path: "/changepassword",
        element: <DashBoardLayout body="changepassword" />
    },
    {
        path: "/visit/detail",
        element: <DashBoardLayout body="detailpage" />
    },
    {
        path: "/specialty",
        element: <Specialty />
    },
    {
        path: "/provider",
        element: <ProviderList />
    },
    {
        path: "/booking",
        element: <Booking />
    },
    {
        path: "/reason",
        element: <Reason />
    },
    {
        path: "/profiledetail",
        element: <ProviderDetail />
    },
    {
        path: "/pharmacy",
        element: <Pharmacy />
    },
    {
        path: "/payment",
        element: <Payment />
    },
    {
        path: "/paymentprocess",
        element: <PaymentProcess />
    },
    {
        path: "/processPayment",
        element: <ProcessPayment />
    },
    {
        path: "/payment/fail",
        element: <PaymentFailed />
    },
    {
        path: "/message",
        element: <MessageHome />
    },
    {
        path: "/printpage",
        element: <PrintLayout />
    },
    {
        path: "/pagenotfound",
        element: <PageNotFound />
    },
    {
        path: "/vital/add",
        element: <VitalsForms />
    },
    {
        path: "/intakeforms",
        element: <IntakeForms />
    },
    {
        path: "/forms",
        element: <PatientForms />
    },
    {
        path: "/docviewer",
        element: <DocumentsViewer />
    },
    {
        path: "/medication-consent",
        element: <MedicationConsent />
    },
    {
        path: "/appointment/summary",
        element: <EncounterSummary />
    },
    {
        path: "/familymember",
        element: <FamilyMember />
    },
    {
        path: "/selectfamilymember",
        element: <SelectFamilyMember />
    },
    {
        path: "/plan/detail",
        element: <MemberPlanDetail />
    },
    {
        path: "/appointment",
        element: <AppointmentMode />
    },
    {
        path: "/test-page-demo-test",
        element: <TestDemoPage />
    },
    {
        path: "*",
        element: <PageNotFound />
    },
    {
        path: "/externalorder/create",
        element: <ExternalOrderCreate  />
    },
];

export default AppRoutes;
