import React, { useContext, useState, useCallback, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { serverGet, serverPost } from '../../../../middleware/http';
import { apiConst } from '../../../../services/constant/constant';
import NotifyContext from '../../../../services/utils/notify';
import ProgressContext from '../../../../services/utils/progress';
import { formValidation, getHtmlContent, getMsg, getSearchParams, removeSearchParam, updateSearchParams } from '../../../../services/utils/validation';
//import FormList from './FormList';
import { useAccountSettings } from '../../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../../services/Context/LanguageContext';

export default function CustomForm(props) {
    const { register, handleSubmit, control, reset, formState: { errors }, watch } = useForm();
    const [formsArr, setFormArr] = useState([]);
    const [formFocus, setFormFocus] = useState('');
    const [formRules, setFormRules] = useState(null);
    const [isWriteForms, setIsWriteForms] = useState(false);


    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const { accountDate } = useAccountSettings();

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const encounter_id = queryParams.get('eid');
    const { getLanguageContent } = useLanguageRes();
    const currentValues = watch();

    const getEncounterFormFields = async () => {

        try {
            progParams(true);
            const form_id = props?.selectedFormId || getSearchParams('frmid');
            const fmid = getSearchParams('fmid');
            if (form_id) {

                setFormArr([]);
                if (location.pathname != "/appointment/summary")
                    updateSearchParams("frmid", form_id);
                let member_id = localStorage.getItem("memberId");
                if (!member_id) {
                    const patient_res = await serverGet(apiConst.patientinfo);
                    if (patient_res?.status == 1 && patient_res?.data && patient_res?.data?.memberId)
                        member_id = patient_res?.data?.memberId;
                }

                const res = await serverGet(`${apiConst.getformlist}${form_id}?mid=${fmid ? fmid : member_id}${encounter_id ? `&eid=${encounter_id}` : ""}`)

                if (res?.data != null && res?.status == 1) {
                    setTimeout(() => {
                        progParams(false);
                        setFormArr(res?.data);
                    }, 2500)
                    const form_focus = res?.data?.find(item => item?.controlId === 1 || item?.controlId === 2 || item?.controlId === 5 || item?.controlId === 6);
                    setFormFocus(form_focus);
                } else {
                    progParams(false);
                }
            }
        } catch (e) {
            progParams(false);
            console.log(e);
        }

    }

    const defaultValuesUpdate = (data) => {
        if (!data || !Array.isArray(data)) {
            return {};
        }

        return data.reduce((acc, curr) => {
            acc[curr.id] = curr.value;
            return acc;
        }, {});
    };


    const handleBack = (e, id) => {
        e.preventDefault();
        if (props?.level == "intake-flow" && id) {
            props?.setSelectedFormId(id);
        } else {
            props?.toggeleForm();
        }
    }

    // console.log(currentValues);
    const handleNextUrl = () => {
        const isPharmacy = getSearchParams("isp");
        const _reascode = getSearchParams("reascode");
        const _typeid = getSearchParams("ftypeid");
        const fmid = getSearchParams("fmid");
        const _phaid = getSearchParams("phaid");
        const _snum = getSearchParams("snum");
        const _brtype = getSearchParams("brtype");
        const _rsid = getSearchParams("rsid") // residingStateId


        if (isPharmacy == "t" && _rsid && _rsid != "") {
            navigate(`/pharmacy?memberid=${getSearchParams("memberid")}&isp=${"t"}&ad=${getSearchParams("ad")}&ts=${encodeURIComponent(getSearchParams("ts"))}&m=${getSearchParams("m")}&dn=${getSearchParams("dn")}&r=${getSearchParams("r")}&eid=${getSearchParams("eid")}&schid=${getSearchParams("schid")}&emid=${getSearchParams("emid")}&did=${getSearchParams("did")}&minP=${getSearchParams("minP")}&spid=${getSearchParams("spid")}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&reascode=${_reascode}${_typeid ? `&ftypeid=${_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${_snum}` : ""}&brtype=${_brtype}`);
        } else {
            navigate(`/appointment/summary?memberid=${getSearchParams("memberid")}&isp=${"f"}&ad=${getSearchParams("ad")}${_phaid ? `&phaid=${_phaid}` : ""}&ts=${encodeURIComponent(getSearchParams("ts"))}&m=${getSearchParams("m")}&dn=${getSearchParams("dn")}&r=${getSearchParams("r")}&eid=${getSearchParams("eid")}&schid=${getSearchParams("schid")}&emid=${getSearchParams("emid")}&did=${getSearchParams("did")}&minP=${getSearchParams("minP")}&spid=${getSearchParams("spid")}${getSearchParams("rsid") ? `&rsid=${getSearchParams("rsid")}` : ""}&reascode=${_reascode}${_typeid ? `&ftypeid=${_typeid}` : ""}${fmid ? `&fmid=${fmid}` : ""}${_snum ? `&snum=${_snum}` : ""}&brtype=${_brtype}`);
        }
    }

    const handleSkipAndSubmit = (e) => {
        e?.preventDefault();
        const nextFormId = formRules?.NextFormId?.formId
        if (props?.level == "intake-flow" && nextFormId) {
            props?.setSelectedFormId(nextFormId);
        } else if (props?.level == "intake-flow") {
            handleNextUrl();
        } else {
            //props?.toggeleForm();
        }
    }

    const selectForm = (id) => {

        if (Array.isArray(props?.formsList) && props?.formsList?.length > 0) {
            const { previousForm, nextForm, slectedForm } = formRule(id);
            const form_rule = {
                selected: slectedForm,
                previousFormId: previousForm,
                NextFormId: nextForm
            }
            setFormRules(form_rule);
        }
    }


    const formRule = (id) => {

        const _forms = [...props?.formsList];

        const selectedIndex = _forms.findIndex(item => item.formId?.toLowerCase() == id?.toLowerCase());
        const slectedForm = _forms.find((item) => item.formId?.toLowerCase() == id?.toLowerCase())
        const previousIndex = selectedIndex > 0 ? selectedIndex - 1 : null;
        const nextIndex = selectedIndex < _forms.length - 1 ? selectedIndex + 1 : null;

        const previousForm = previousIndex !== null ? _forms[previousIndex] : null;
        const nextForm = nextIndex !== null ? _forms[nextIndex] : null;
        if (slectedForm) {
            document.title = slectedForm?.clientTitle;
            if (slectedForm?.write) {
                setIsWriteForms(true);
            }
        }
        return { previousForm, nextForm, slectedForm };
    };


    const onSubmit = (data) => {
        const formdata = [];
        let forms_Arr = [...formsArr];
        if (isWriteForms) {
            forms_Arr?.forEach(item => {
                if ((Object.keys(data).includes(item.id) || (Object.keys(data).includes(`${item.id}_month`) && Object.keys(data).includes(`${item.id}_year`)))) {
                    if (Array.isArray(data[item.id])) {
                        item.value = data[item.id]?.join('~');
                    } else if (data[item.id] instanceof Date) {
                        item.value = data[item.id];
                    } else if (data.hasOwnProperty(`${item.id}_month`) && data.hasOwnProperty(`${item.id}_year`)) {
                        item.value = `${data[`${item.id}_year`]}~${data[`${item.id}_month`]}`;
                    } else {
                        item.value = data[item.id];
                    }
                }
            });
        }



        forms_Arr?.forEach(item => {
            if (item?.parentId && item?.rule) {
                removeChild(item?.rule, item)
            }
        });

       // console.log(forms_Arr);

        forms_Arr?.filter((item) => item?.controlId !== 3 && item?.controlId !== 4  && item.value != undefined)?.map((list) => {
            formdata.push({
                encounterId: encounter_id || null,
                formId: list?.formId,
                formFieldId: list?.id,
                value: list?.value?.toString(),
                //title: list?.clientTitle
            })
        })

        //console.log(formdata);


        progParams(true);
        serverPost(apiConst.formsave, formdata).then((res) => {
            progParams(false);
            try {
                if (res?.status == '1') {
                    notifyToast(res.messageCode[0]);
                    handleSkipAndSubmit();
                }
                else {
                    var error = JSON.parse(res.data.errorMessage);
                    notifyToast(error.messageCode[0]);
                }

            } catch (e) {

                console.log(e);
            }
        })

    };


    function removeChild(value, ite) {
        try {
            if (value) {
                const temp = formsArr?.filter((list) => list?.id == ite?.parentId);
                if (temp?.length > 0 && temp[0]?.parentId) {
                    if (!temp[0]?.value) {
                        return ite.value = "";
                    } else {
                        const obj = JSON.parse(value);
                        if (obj && obj?.RecordId && obj?.Value) {
                            let field = "";
                            if (typeof obj?.RecordId == "string") {
                                field = currentValues[obj?.RecordId?.toLowerCase()];
                            }
                            if (Array.isArray(field)) {
                                let _value = obj?.Value?.split("~");
                                const res = _value.some(item => field.includes(item));
                                if (res) {
                                    return true;
                                }
                                 
                            }
                            if (Array.isArray(obj?.Value?.split("~")) && obj?.Value?.split("~")?.includes(field)) {
                                return true;
                            }

                            if (field == obj?.Value) {
                                return true;
                            }
                            return ite.value = "";
                        }
                    }

                } else {
                    const obj = JSON.parse(value);
                    if (obj && obj?.RecordId && obj?.Value) {
                        let field = "";
                        if (typeof obj?.RecordId == "string") {
                            field = currentValues[obj?.RecordId?.toLowerCase()];
                        }
                        if (Array.isArray(field)) {
                            let _value = obj?.Value?.split("~");
                            const res = _value.some(item => field.includes(item));
                            if (res) {
                                return true;
                            }                        }
                        if (Array.isArray(obj?.Value?.split("~")) && obj?.Value?.split("~")?.includes(field)) {
                            return true;
                        }

                        if (field == obj?.Value) {
                            return true;
                        }
                        return ite.value = "";
                    } 
                }
            }
        } catch (e) {
            console.error(e)
        }
    }




    console.log(currentValues);


    function checkChild(value, ite) {
        try {
            if (value) {
                const temp = formsArr?.filter((list) => list?.id == ite?.parentId);
                if (temp?.length > 0 && temp[0]?.parentId) {
                    const obj = JSON.parse(value);
                    if (obj && obj?.RecordId && obj?.Value) {
                        let field = "";
                        if (typeof obj?.RecordId == "string") {
                            field = currentValues[obj?.RecordId?.toLowerCase()];
                        }
                        if (Array.isArray(field)) {
                            let _value = obj?.Value?.split("~");

                            const _res = _value.some(item => field.includes(item))

                            if (_res) {
                                return checkChild(temp[0]?.rule, temp[0]);

                            } else {
                                return false;
                            }

                        }
                        if (Array.isArray(obj?.Value?.split("~")) && obj?.Value?.split("~")?.includes(field)) {
                            return checkChild(temp[0]?.rule, temp[0]);

                            //return true;
                        }

                        if (field == obj?.Value) {
                            return checkChild(temp[0]?.rule, temp[0]);

                            //return true;
                        }
                        return false;
                    } return false;
                } else {
                    const obj = JSON.parse(value);
                    if (obj && obj?.RecordId && obj?.Value) {

                        let field = "";
                        if (typeof obj?.RecordId == "string") {
                            field = currentValues[obj?.RecordId?.toLowerCase()];
                        }
                        if (Array.isArray(field)) {
                            let _value = obj?.Value?.split("~");
                            return _value.some(item => field.includes(item));
                        }
                        if (Array.isArray(obj?.Value?.split("~")) && obj?.Value?.split("~")?.includes(field)) {
                            return true;
                        }

                        if (field == obj?.Value) {
                            return true;
                        }
                        return false;
                    } return false;
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const getDisabledField = (prop, isWrite) => {
        if (prop?.encounter?.statusId == 3 || prop?.encounter?.statusId == 4) {
            return true;
        } else if (prop?.level == "encounter-forms" && !prop?.tabData?.write) {
            return true;
        } else if (!isWrite) {
            return true;
        } else {
            return false;
        }

    }




    const testHierarchy = (data) => {

        setFormArr(data)

    }


    useEffect(() => {
        if (props?.tabData && props?.tabData?.isForm && props?.tabData?.id) {
            props?.setSelectedFormId(props?.tabData?.id);
        }

    }, [props?.tabData])


    useEffect(() => {
        setTimeout(() => {
            getEncounterFormFields();
        }, 800)

        return () => {
            setFormArr([]);
            if (props?.level != "intake-flow") {
                removeSearchParam("frmid");
            }

        };

    }, [props?.selectedFormId])


    useEffect(() => {
        const form_id = props?.selectedFormId || getSearchParams('frmid');

        if (form_id) {

            setTimeout(() => {
                selectForm(form_id);
            }, 1000)

        }


    }, [props?.formsList, props?.selectedFormId])

    useEffect(() => {
        const defaultValues = defaultValuesUpdate(formsArr);
        reset(defaultValues);
    }, [reset, formsArr]);


    useEffect(() => {
        //testHierarchy(sampleData);
    }, [])

    //console.log(formsArr)
    //console.log(currentValues)

    return (
        <>
            <div>
                {formsArr?.length > 0 && <h4>{formsArr[0]?.formTitle} </h4>}
                {formsArr?.length > 0 && (
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        {Array.isArray(formsArr) && formsArr?.map((list, index, arr) => (
                            <>

                                <div key={`forms${list?.id}`}>
                                    {(list?.controlId == 1 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div className="my-4" key={list?.id}>
                                            <label htmlFor={list?.id} className="form-label">
                                                {list?.clientTitle}{list?.isRequired && <span className="text-danger">*</span>}
                                            </label>
                                            <Controller
                                                name={list?.id}
                                                control={control}
                                                defaultValue={list?.value}
                                                rules={
                                                    {
                                                        required: list?.isRequired ? getMsg(301) : false,
                                                        validate: list?.isRequired ? formValidation.validateNoEmptySpaces : undefined,
                                                    }
                                                }
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        disabled={getDisabledField(props, isWriteForms)}
                                                        type="text"
                                                        autoFocus={formFocus?.id == list?.id ? true : false}
                                                        defaultValue={field?.value}
                                                        className="form-control"
                                                        id={list?.id}
                                                        placeholder={""}
                                                    />
                                                )}
                                            />
                                            {errors[list?.id]?.message && <p className="text-danger">{errors[list?.id]?.message}</p>}
                                        </div>
                                        : ""
                                    }

                                    {(list?.controlId == 2 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div className="my-4" key={list?.id}>
                                            {list?.clientTitle != arr[index - 1]?.clientTitle ? <p>{list?.clientTitle}{(list?.isRequired && list?.clientTitle) && <span className="text-danger">*</span>}</p> : ''}
                                            {(errors[list?.id]?.message && list?.clientTitle != arr[index - 1]?.clientTitle && list?.clientTitle != "") && <p className="text-danger mt-0">{errors[list?.id]?.message}</p>}
                                            <input disabled={getDisabledField(props, isWriteForms)}
                                                defaultChecked={list?.fieldOptionId == list?.answeredFieldOptionId ? true : list?.fieldOptionDefault ? true : false}
                                                value={list?.fieldOptionId}
                                                autoFocus={formFocus?.id == list?.id ? true : false}
                                                type="checkbox"
                                                className="form-check-input" id={`${list?.id}_${index}`}
                                                {...register(list?.id, {
                                                    required: list?.isRequired ? getMsg(301) : false,
                                                })}
                                            />
                                            <label className="form-check-label ms-2 d-inline" htmlFor={`${list?.id}_${index}`}>{list?.fieldOption}</label>
                                            {(errors[list?.id]?.message && list?.clientTitle != arr[index - 1]?.clientTitle && list?.clientTitle == "") && <p className="text-danger mt-0">{errors[list?.id]?.message}</p>}
                                        </div>
                                        : ''
                                    }

                                    {list?.controlId == 3 ?
                                        <div className="my-4" key={list?.id}>
                                            <p className='fw-bold'>{list?.clientTitle}</p>
                                        </div> : ''
                                    }

                                    {list?.controlId == 4 ?
                                        < div className=" border border-2 rounded rounded-2 p-4" key={list?.id}>
                                            <div className={`${list?.styleId == '3' ? "scroll-list-container bg-light text-dark p-3" : ''}`} dangerouslySetInnerHTML={{ __html: list?.fieldOption }}>
                                            </div>
                                        </div>
                                        : ""
                                    }

                                    {list?.controlId == 5 ?
                                        <div className="my-4" key={list?.id}>
                                            <label className="form-label" htmlFor={`${list?.id}`}>{list?.clientTitle}{list?.isRequired && <span className="text-danger star">*</span>}</label>
                                            <Controller
                                                name={list?.id}
                                                control={control}

                                                //defaultValue={new Date()}
                                                defaultValue={list?.value ? new Date(list?.value) : null}
                                                rules={{ required: list?.isRequired ? getMsg(301) : false }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        autoFocus={formFocus?.id == list?.id ? true : false}
                                                        name={list?.id}
                                                        id={list?.id}
                                                        selected={field.value}
                                                        disabled={getDisabledField(props, isWriteForms)}
                                                        dateFormat={accountDate?.value ? accountDate?.value?.toLowerCase()?.replace(/mm/, "MM") : "MM/dd/yyyy"}
                                                        className="form-control"
                                                        onChange={field.onChange}
                                                        placeholderText={list?.clientTitle}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        wrapperClassName="w-100"
                                                        dropdownMode="select"
                                                    // maxDate={new Date()}
                                                    />
                                                )}
                                            />
                                            {errors[list?.id]?.message && <p className="text-danger">{errors[list?.id]?.message}</p>}
                                        </div>
                                        : ""
                                    }

                                    {(list?.controlId == 6 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div className="my-4" key={list?.id}>


                                            {list?.clientTitle != arr[index - 1]?.clientTitle ? <p>{list?.clientTitle}{(list?.isRequired && list?.clientTitle) && <span className="text-danger">*</span>}</p> : ''}
                                            {(errors[`${list?.id}`]?.message && list?.clientTitle != arr[index - 1]?.clientTitle && list?.clientTitle != '') && <p className="text-danger mt-0">{errors[`${list?.id}`]?.message}</p>}
                                            <input
                                                disabled={getDisabledField(props, isWriteForms)}
                                                defaultChecked={list?.fieldOptionId == list?.answeredFieldOptionId ? true : list?.fieldOptionDefault ? true : false}
                                                value={list?.fieldOptionId} autoFocus={formFocus?.id == list?.id ? true : false}
                                                type="radio"
                                                className="form-check-input" id={`${list?.id}_${index}`}
                                                {...register(list?.id, {

                                                    required: list?.isRequired ? getMsg(301) : false
                                                })} />

                                            <label className="form-check-label ms-2 d-inline" htmlFor={`${list?.id}_${index}`}>{list?.fieldOption}</label>
                                            {errors[list?.id]?.message && list?.clientTitle != arr[index - 1]?.clientTitle && list?.clientTitle == '' && <p className="text-danger">{errors[list?.id]?.message}</p>}
                                        </div>
                                        : ''
                                    }

                                    {(list?.controlId == 7 && list?.value) ?
                                        <div className="my-4" key={list?.id}>
                                            <label htmlFor={list?.id} className="form-label">
                                                {list?.clientTitle}
                                            </label>
                                            <Controller
                                                name={`${list.id}`}
                                                control={control}
                                                defaultValue={list?.value}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        disabled
                                                        type="text"
                                                        className="form-control"
                                                        id={`${list.id}`}
                                                    />
                                                )}
                                            />
                                        </div>
                                        : ""
                                    }

                                    {(list?.controlId == 8 && ((list.rule && checkChild(list.rule, list)) || (list.parentId == null))) ?
                                        <div>
                                            <label htmlFor={list.id} className="form-label">
                                                {list?.clientTitle}{list?.isRequired && <span className="text-danger">*</span>}
                                            </label>
                                            <div className="my-4 d-flex">
                                                <div className="input-group m-0 me-sm-2">
                                                    <Controller
                                                        name={`${list.id}_year`}
                                                        control={control}
                                                        defaultValue={Array.isArray(list?.value?.split("~")) && list.value.split("~")?.length > 0 ? list.value.split("~")[0] : ""}
                                                        rules={
                                                            {
                                                                required: list?.isRequired ? getMsg(301) : false,
                                                                pattern: {
                                                                    value: /^(?:\b\d{1,2}|0[0-9])$/,
                                                                    message: "Please enter a number from 0 to 99"
                                                                }
                                                            }
                                                        }
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                disabled={getDisabledField(props, isWriteForms)}
                                                                type="text"
                                                                className="form-control"
                                                                id={`${list.id}_year`}
                                                                placeholder="Year"
                                                            />
                                                        )}
                                                    />
                                                    <span className="input-group-text">Year(s)</span>
                                                </div>
                                                <div className="input-group">
                                                    <Controller
                                                        name={`${list.id}_month`}
                                                        control={control}
                                                        rules={
                                                            {
                                                                required: list?.isRequired ? getMsg(301) : false,
                                                            }
                                                        }
                                                        defaultValue={Array.isArray(list?.value?.split("~")) && list.value.split("~")?.length > 1 ? list.value.split("~")[1] : ""}
                                                        render={({ field }) => (
                                                            <select {...field} className="form-select p-2" id={`${list.id}_month`}
                                                                disabled={getDisabledField(props, isWriteForms)}
                                                            >
                                                                <option value="">Month</option>
                                                                <option value="0">0</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                            </select>
                                                        )}
                                                    />
                                                    <span className="input-group-text">Month(s)</span>
                                                </div>
                                            </div>
                                            {(errors[`${list?.id}_year`]?.message || errors[`${list?.id}_month`]?.message) && (
                                                <p className="text-danger">{errors[`${list?.id}_year`]?.message || errors[`${list?.id}_month`]?.message}</p>
                                            )}
                                        </div>
                                        : ""
                                    }
                                    {(list?.controlId == 9 || list?.controlId == 10 || list?.controlId == 11) ?
                                        <div key={list?.id} className="mb-2">
                                            <span className={`${getHeadingClass(list?.controlId)} fw-normal`}>{list?.clientTitle}</span>
                                        </div> : ''
                                    }
                                    {(list?.controlId == 12 && list?.clientTitle && list?.clientTitle?.trim()) ?
                                        <div key={list?.id} className="mb-2" dangerouslySetInnerHTML={{ __html: getHtmlContent(list?.clientTitle) }}>
                                        </div> : ''
                                    }


                                </div>



                            </>
                        ))}
                        <div className="mb-2 text-end">
                            {(formRules?.selected && formRules?.selected?.isRequired !== true && props?.level == "intake-flow") && (
                                <button className="btn btn-primary me-2" onClick={(e) => handleSkipAndSubmit(e)}>Skip</button>
                            )}
                            {((formRules?.previousFormId || props?.level == "patient-forms") && (props?.level != "encounter-forms")) && (
                                <button className="btn btn-primary me-2" onClick={(e) => handleBack(e, formRules?.previousFormId?.formId)}>Back</button>
                            )}
                            {(props?.encounter?.statusId != 3 && props?.encounter?.statusId != 4 && (props?.level == "encounter-forms" ? props?.tabData?.write && isWriteForms : isWriteForms)) && (
                                <button className="btn btn-primary me-2">{getLanguageContent("submt")}</button>
                            )}
                        </div>
                    </form>
                )}
                {props?.summaryPage && <hr />}
            </div>
        </>
    )
}

export const getHeadingClass = (id) => {
    switch (id) {
        case 9:
            return "h4"
        case 10:
            return "h5"
        case 11:
            return "h6"
        default:
            return ""
    }

}




const sampleData = [
    {
        "id": "2d2207e8-815b-4b8d-8cfd-a2ed95bd3924",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "c9607475-830d-4fb8-98f4-fb8b1e63a762",
        "isRequired": true,
        "order": 0,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "<div><ol style=\"list-style-type: lower-alpha\"><li><p>To ensure that we provide you with the safest and most effective acne treatment, please complete the Health and Acne Profile Questionnaire. This comprehensive form collects essential details about your health, medication history, and acne treatment preferences.</p></li><li><p><strong>Purpose:</strong><ol style=\"list-style-type: lower-roman\"><li><p><b>Safety:</b> Identify any health conditions or medications that may influence your treatment options.</p></li><li><p><b>Customisation:</b> Tailor a treatment plan specific to your acne history and overall health needs.</p></li><li><p>Please answer all questions accurately on your smart device to help us deliver the best care tailored to your needs.</p></li></ol></p></li></ol></div>",
        "providerTitle": "<div><ol style=\"list-style-type: lower-alpha\"><li><p>To ensure that we provide you with the safest and most effective acne treatment, please complete the Health and Acne Profile Questionnaire. This comprehensive form collects essential details about your health, medication history, and acne treatment preferences.</p></li><li><p><strong>Purpose:</strong><ol style=\"list-style-type: lower-roman\"><li><p><b>Safety:</b> Identify any health conditions or medications that may influence your treatment options.</p></li><li><p><b>Customisation:</b> Tailor a treatment plan specific to your acne history and overall health needs.</p></li><li><p>Please answer all questions accurately on your smart device to help us deliver the best care tailored to your needs.</p></li></ol></p></li></ol></div>",
        "controlId": 12,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": null,
        "value": "",
        "fieldOption": "",
        "fieldOptionDefault": null,
        "answeredFieldOptionId": null,
        "fieldOptionId": null,
        "rule": null,
        "parentId": null
    },
    {
        "id": "dd442e03-943d-4644-821c-67520462fe55",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "1c0d1ca4-7e62-43df-82fa-7ff02b78ecae",
        "isRequired": true,
        "order": 4,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Current Contraception Practice",
        "providerTitle": "Current Contraception Practice",
        "controlId": 9,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": null,
        "value": "",
        "fieldOption": "",
        "fieldOptionDefault": null,
        "answeredFieldOptionId": null,
        "fieldOptionId": null,
        "rule": null,
        "parentId": null
    },
    {
        "id": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
        "isRequired": true,
        "order": 5,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you currently using any contraception?",
        "providerTitle": "Are you currently using any contraception?",
        "controlId": 6,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "value": "",
        "fieldOption": "Yes",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 1,
        "rule": null,
        "parentId": null
    },
    {
        "id": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "1bd00f8f-dbd1-4736-bf37-a0d9e70922a2",
        "isRequired": true,
        "order": 5,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you currently using any contraception?",
        "providerTitle": "Are you currently using any contraception?",
        "controlId": 6,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce",
        "value": "",
        "fieldOption": "No",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 2,
        "rule": null,
        "parentId": null
    },
    {
        "id": "9785e22f-befd-45cb-b342-74a325cc9753",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "f71204aa-5aae-46eb-b542-524d821fb8ff",
        "isRequired": true,
        "order": 6,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you using Abstinence?",
        "providerTitle": "Are you using Abstinence?",
        "controlId": 6,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": null,
        "value": "",
        "fieldOption": "Yes",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 1,
        "rule": "{\"RecordId\":\"7266A949-EAA8-4FA7-A68F-90B3B237FDCE\",\"RecordTypeId\":29,\"OperatorId\":1,\"Value\":\"1\",\"DataTypeId\":1}",
        "parentId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce"
    },
    {
        "id": "9785e22f-befd-45cb-b342-74a325cc9753",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "f71204aa-5aae-46eb-b542-524d821fb8ff",
        "isRequired": true,
        "order": 6,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you using Abstinence?",
        "providerTitle": "Are you using Abstinence?",
        "controlId": 6,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": null,
        "value": "",
        "fieldOption": "No",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 2,
        "rule": "{\"RecordId\":\"7266A949-EAA8-4FA7-A68F-90B3B237FDCE\",\"RecordTypeId\":29,\"OperatorId\":1,\"Value\":\"1\",\"DataTypeId\":1}",
        "parentId": "7266a949-eaa8-4fa7-a68f-90b3b237fdce"
    },
    {
        "id": "9785e22f-befd-45cb-b342",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "f71204aa-5aae-46eb-b542-524d821fb8ff",
        "isRequired": true,
        "order": 6,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you using check child?",
        "providerTitle": "Are you using check child?",
        "controlId": 6,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": null,
        "value": "",
        "fieldOption": "Yes",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 1,
        "rule": "{\"RecordId\":\"9785e22f-befd-45cb-b342-74a325cc9753\",\"RecordTypeId\":29,\"OperatorId\":1,\"Value\":\"1\",\"DataTypeId\":1}",
        "parentId": "9785e22f-befd-45cb-b342-74a325cc9753"
    },
    {
        "id": "9785e22f-befd-45cb-b342",
        "formId": "588d2ead-95bf-4d99-8685-55f347f6fbde",
        "fieldId": "f71204aa-5aae-46eb-b542-524d821fb8ff",
        "isRequired": true,
        "order": 6,
        "styleId": 2,
        "formTitle": "Health and Acne Profile Questionnaire Overview",
        "clientTitle": "Are you using check child?",
        "providerTitle": "Are you using check child?",
        "controlId": 6,
        "dataTypeId": 1,
        "formInputId": "affba15b-a283-4027-ac39-53eaa43a3a53",
        "formFieldId": null,
        "value": "",
        "fieldOption": "No",
        "fieldOptionDefault": false,
        "answeredFieldOptionId": null,
        "fieldOptionId": 2,
        "rule": "{\"RecordId\":\"9785e22f-befd-45cb-b342-74a325cc9753\",\"RecordTypeId\":29,\"OperatorId\":1,\"Value\":\"1\",\"DataTypeId\":1}",
        "parentId": "9785e22f-befd-45cb-b342-74a325cc9753"
    },
    {
        "id": "7d788244-9549-4d9f-ef80370788b6",
        "formId": "be051a68-6f5f-4796-aa19-2a70c53a079c",
        "fieldId": "6d1e5dd2-4c82-43aa-8142-09bbf8c6c74e",
        "isRequired": true,
        "order": 19,
        "styleId": 2,
        "formTitle": "Follow-up Patient Questionnaire",
        "clientTitle": "Please specify the side effects you have experienced",
        "providerTitle": "Please specify the side effects you have experienced",
        "controlId": 1,
        "dataTypeId": 1,
        "formInputId": "7559d663-3c72-4cdd-bb1c-f8aa36b53b02",
        "formFieldId": null,
        "value": "",
        "fieldOption": "",
        "fieldOptionDefault": null,
        "answeredFieldOptionId": null,
        "fieldOptionId": null,
        "rule": "{\"RecordId\":\"9785e22f-befd-45cb-b342\",\"RecordTypeId\":29,\"OperatorId\":1,\"Value\":\"1\",\"DataTypeId\":1}",
        "parentId": "9785e22f-befd-45cb-b342"
    }
]