import React, { useEffect, useState, useContext } from "react";
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { formValidation, getAuthProfile, getMsg, getSearchParams, isEnabled } from "../../../services/utils/validation";
import { PasswordField } from "../../../services/utils/forms";
import { serverGet, serverPost } from "../../../middleware/http";
import { apiConst } from "../../../services/constant/constant";
import { useProfile } from "../../../services/utils/profileContext";
import NotifyContext from "../../../services/utils/notify";
import ProgressContext from "../../../services/utils/progress";
import { useAccountSettings } from "../../../services/Context/AccSettingContext";
import TermsAndPolicy from "./TermsAndPolicy";

export default function AccountActivation() {
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();
    const [member, setMember] = useState(null);
    const [userValidation, setUserValidation] = useState(null);
    const { checkUserProfile } = useProfile();
    const { notifyToast } = useContext(NotifyContext);
    const { progParams } = useContext(ProgressContext);
    const { accountSettings } = useAccountSettings();

    const checkUser = (id) => {
        serverGet(`${apiConst.validateAccount}${id}`).then((res) => {
            if (res?.data && res?.status == 1) {
                setMember(res?.data);
                setValue("email", res?.data?.email)
            } else {
                const error = JSON.parse(res.data?.errorMessage);
                const eCode = error?.messageCode[0]
                setUserValidation(eCode);
                const errorCodes = ["226", "227"];
                if (!errorCodes.includes(eCode)) {
                    notifyToast(eCode);
                }
            }
        })
    }
    //console.log(userValidation);
    const onSubmit = async (data) => {
        try {
            progParams(true);
            const formData = {
                memberId: member?.id,
                Password: data?.password,
                confirmPassword: data?.confirmPassword
            }

            const res = await serverPost(apiConst.activateAccount, formData);

            if (res?.status == 1) {
                const loginRes = await serverPost(`${apiConst.postlogin}`, { username: member?.email, Password: data?.password });
                if (loginRes) {
                    getAuthProfile.saveProfileAuth();
                    const _isProfile = await checkUserProfile();
                    const profileUrl = `/addprofile`;
                    const identificationUrl = `/idverification/upload`;
                    if (!_isProfile?.pofileCompleted) {
                        //window.history.replaceState(null, '', profileUrl);
                        return window.location.href = profileUrl;
                    } else if (isEnabled(accountSettings, "CPI", 1) && !_isProfile?.idVerification) {
                        //window.history.replaceState(null, '', identificationUrl);
                        return window.location.href = identificationUrl;
                    }
                    return window.location.href = "/dashboard";
                }
            } else {
                const error = JSON.parse(res.data?.errorMessage);
                notifyToast(error?.messageCode[0]);
            }
        } catch (e) {
            console.error(e)
        } finally {
            progParams(false);
        }
    }




    useEffect(() => {
        if (getAuthProfile.getProfileAuth()) {
            return window.location.href = "/dashboard";
        }

        document.title = "Account activation"
        const _memberId = getSearchParams("mid");

        if (_memberId) {
            checkUser(_memberId);
        }

    }, [])



    return (
        <>
            {(member) && (
                <h6 className="card-title text-uppercase fw-bold mb-3">Account Activation</h6>
            )}
            {member && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="my-3">
                            <label htmlFor="email" className="form-label">Email address<span className="text-danger">*</span></label>
                            <input type="text" disabled className="form-control body-secondary-color" id="email"  {...register("email")} />
                        </div>

                        <div className="mb-3">
                            <PasswordField
                                name="password"
                                label="Password"
                                errors={errors}
                                register={register}
                                icon={true}
                                placeholder="Enter password"
                                validationSchema={{
                                    required: getMsg(301),
                                    maxLength: { value: 50, message: getMsg("305") },
                                    pattern: formValidation.password.pattern,
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <PasswordField
                                name="confirmPassword"
                                label="Confirm password"
                                errors={errors}
                                register={register}
                                placeholder="Enter confirm password"
                                icon={true}
                                validationSchema={{
                                    required: getMsg(301),
                                    validate: value => value === getValues('password') || getMsg(316),
                                    maxLength: {
                                        value: 50, message: getMsg("305")
                                    }
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <div className="d-flex">
                                <input type="checkbox" className="form-check-input" id="term&cond" {...register("termscond", { required: getMsg(301) })} />
                                <label className="form-check-label body-secondary-color ms-2">
                                    <label htmlFor="term&cond">I agreed to the</label>
                                    <TermsAndPolicy />
                                </label>
                            </div>
                            {errors.termscond?.message && <p className="text-danger m-0">{errors.termscond?.message}</p>}
                        </div>

                        <div className="mb-2">
                            <button className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </>
            )}


            {(userValidation == 226 || userValidation == 227) && (
                <>
                    <div className="text-center my-5">
                        <p className="fw-bold">{getMsg(userValidation)}</p>

                        {userValidation == 227 && (
                            <div className="my-4">
                                <Link to="/security/login" className="btn btn-primary">Sign in</Link>
                            </div>
                        )}
                    </div>

                </>
            )}
        </>
    )
}

//<div className="mb-5">
//    <Link to="/security/login" className="btn btn-primary ">Signin</Link>
//</div>