import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getMsg } from "../../../../src/services/utils/validation";
import ProgressContext from "../../../services/utils/progress";
import { apiConst } from "../../../services/constant/constant";
import NotifyContext from "../../../services/utils/notify";
import { serverPost } from "../../../middleware/http";
import { useLanguageRes } from "../../../services/Context/LanguageContext";

export default function AddMessage({ toggleMessage, msgId, list, level, messageSubject, getList, getInboxList, fieldDisabled, backButton, edit }) {
    const [msgcontent, setMsgcontent] = useState("");
    const { getLanguageContent } = useLanguageRes();

    const { progParams } = useContext(ProgressContext);
    const { notifyToast } = useContext(NotifyContext);
    const textRef = useRef();
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    //console.log(backButton);


    const onSubmit = (data) => {
        //console.log(data);
        progParams(true);
        let member_id = localStorage.getItem("memberId");

        const formData = {
            fromMemberId: member_id,
            recordTypeId: 5, //appConst.recordType.patientLevel
            subject: data.subject,
            content: data.msgContent,
            orderNo: 1
        }

        if (msgId) {
            formData.parentId = msgId;

            if (Array.isArray(list)) {
                formData.subject = list[0].subject;
                //formData.subject = `Re: ${list[0].subject}`;
                formData.orderNo = list.length + 1;
            }

        }

        //console.log(formData);

        progParams(true);
        serverPost(apiConst.saveMessage, formData).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    notifyToast(106);

                    if (level == "new") {
                        toggleMessage();
                        getInboxList();
                    } else if (level == "edit") {
                        getList();
                    }
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        }).catch((e) => {
            progParams(false)
            var error = JSON.parse(e?.response?.data?.errorMessage);
            notifyToast(error?.messageCode[0]);
        })
    }

    const stripTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const validateConsentContent = (value) => {
        //Required validation
        if (!value || value.trim() === '' || stripTags(value).trim() === '') {
            return getMsg(301);
        }

        return true;
    };


    const handleBack = (e) => {

        e.preventDefault();
        e.stopPropagation();


        backButton();
    }


    const toolbarOptions = [
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
    ];

    const modules = {
        toolbar: toolbarOptions,
    };


    useEffect(() => {
        if (msgId && level == "edit") {

            reset({ subject: messageSubject });
            textRef?.current?.focus();
        }
    }, [messageSubject])


    //console.log(list);

    return (
        <>
            <div className={msgId ? "" : "card border-0 "}>
                <div className={msgId ? "" : "card-body"}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="mb-3">
                            <input type="text" autoFocus={!fieldDisabled} className="form-control" id="msgsubject" disabled={fieldDisabled} placeholder={getLanguageContent("msgsub")} {...register("subject", { required: getMsg(301), maxLength: { value: 150, message: getMsg("305") } })} />
                            {errors.subject?.message && <p class="text-danger">{errors.subject?.message}</p>}
                        </div>
                        <div className="mb-3">
                            <Controller
                                name="msgContent"
                                control={control}
                                defaultValue={msgcontent}
                                rules={{ validate: validateConsentContent }}
                                render={({ field }) => (
                                    <ReactQuill  ref={textRef} className="border-top" theme="snow" placeholder={getLanguageContent("msgage")} modules={modules} value={field.value} id="taConsentcontent"
                                        onChange={(content) => {
                                            field.onChange(content);
                                            setMsgcontent(content);
                                        }}
                                    />
                                )}
                            />
                            {errors.msgContent?.message && <p className="text-danger">{errors.msgContent?.message}</p>}
                        </div>

                        <div className="text-end my-3">
                            <button className={`${msgId && level == "edit" ? "d-inline d-xl-none" : ""} btn btn-lg btn-secondary`} onClick={(e) => handleBack(e)} type="button">
                                {getLanguageContent("bck")}
                            </button>

                            {(level == "new" || edit) && (
                                <button className="ms-2 btn btn-lg btn-primary">{getLanguageContent("msgsnd")}</button>
                            )}
                        </div>
                    </ form>
                </div>
            </div>
        </>
    )
}



