import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { serverGet, serverPut, serverDelete } from '../../middleware/http';
import { apiConst, appConst } from '../constant/constant';
import NotifyContext from './notify';
import ProgressContext from './progress';
import { ecnSession, getAuthProfile, getMemberId, getSearchParams, isEnabled, updateSearchParams } from './validation';
import { useManageResource } from '../Context/ManageResourceContext';
//import { useAccountSettings } from '../Context/AccSettingContext';

const ProfileContext = createContext();

export const useProfile = () => {
    return useContext(ProfileContext);
};


export const ProfileProvider = ({ children }) => {
    const [profileContext, setProfileContext] = useState(null);
    const [patientInfo, setPatientInfo] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [contextFamilyList, setContextFamilyList] = useState(null);
    const [isfamilyMember, setisFamilyMember] = useState(false);
    //const [selectedFamilyMember,setisFamilyMember] = useState([]);

    const { notifyToast } = useContext(NotifyContext);
    const { progParams } = useContext(ProgressContext);
    const { resourceList, getCurrentResource } = useManageResource();
    //const { accountSettings } = useAccountSettings();

    const location = useLocation();

    const updateProfile = (newProfile) => {
        setProfileContext(newProfile);
        ecnSession(newProfile?.resProfile?.id, "mid");
        localStorage.setItem("memberId", newProfile?.resProfile?.id);
    };

    const getprofile = () => {
        progParams(true);
        serverGet(apiConst.getuserprofile).then((res) => {
            progParams(false);
            try {
                if (res.data != null) {
                    //contact
                    const res_profile = JSON.parse(res?.data?.profile);
                    const res_contact = JSON.parse(res?.data?.contact);
                    const response = {
                        resProfile: res_profile?.data,
                        resContact: res_contact?.data,
                    }
                    ecnSession(res_profile?.data?.id, "mid");
                    localStorage.setItem("memberId", res_profile?.data?.id);
                    setProfileContext(response);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const submitProfile = (data) => {
        progParams(true);
        serverPut(`${apiConst.updateprofilephoto}/${profileContext?.resProfile?.id}`, { photo: data }).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    setImageUrl(data);
                    getprofile();
                    notifyToast(res.messageCode[0]);
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }
            } catch (e) {
                console.log(e);
            }
        }).catch((e) => {
            console.log(e);
            var error = JSON.parse(e?.response?.data?.errorMessage);
            notifyToast(error?.messageCode[0]);
        })

    };


    const deleteProfilePhoto = () => {
        progParams(true);
        serverDelete(`${apiConst.deleteprofilephoto}/${profileContext?.resProfile?.id}`, "").then((res) => {
            progParams(false);
            try {
                if (res.status === 1) {
                    setImageUrl(null);
                    notifyToast(res.messageCode[0]);
                    getprofile();
                } else {
                    var error = JSON.parse(res?.data?.errorMessage);
                    notifyToast(error?.messageCode[0]);
                }

            } catch (error) {
                console.log(error);
            }
        }).catch((e) => {
            console.log(e)
        })
    }


    const getPatient = () => {
        progParams(true);
        serverGet(apiConst.patientinfo).then((res) => {
            progParams(false);
            try {
                if (res?.data != null) {
                    setPatientInfo(res?.data);
                    const residingStateId = getSearchParams("rsid")
                    const residingStateIdStatus = getSearchParams("rsidstatus") // status updtaed to y else n
                    if (residingStateIdStatus != "y" && residingStateId && residingStateId?.trim()) {
                        updateResidingState({
                            residingStateId: residingStateId,
                            memberId: res?.data?.memberId
                        })
                    }
                } else {
                    //var error = JSON.parse(res?.data?.errorMessage);
                    //notifyToast(error?.messageCode[0]);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }
    function updateResidingState(data) {

        progParams(true);
        serverPut(apiConst.updateResiding, data).then((res) => {
            progParams(false);
            try {
                if (res?.status == 1) {
                    getPatient();
                    updateSearchParams("rsidstatus", "y")
                } else {
                    //var error = JSON.parse(res?.data?.errorMessage);
                    //notifyToast(error?.messageCode[0]);
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    const checkUserProfile = async () => {
        try {
            if (getAuthProfile.getProfileAuth()) {

                let _resourceList = getCurrentResource(appConst.resource.moduleMenu, "EQ7");
                let _profileContext = profileContext?.resProfile;
                let _contactContext = profileContext?.resContact;
                let _profile = patientInfo

                if (!_profile) {
                    const res = await serverGet(`${apiConst.patientinfo}`);
                    _profile = res?.data;
                    //setPatientInfo(res?.data);
                }


                if (!_resourceList) {
                    const res = await serverGet(`${apiConst.getResource}`);
                    _resourceList = res?.data?.resources;
                }

                if (!_profileContext) {
                    const res = await serverGet(apiConst.getuserprofile);
                    if (res?.data != null) {
                        const res_profile = JSON.parse(res?.data?.profile);
                        const res_contact = JSON.parse(res?.data?.contact);
                        _profileContext = res_profile?.data;
                        _contactContext = res_contact?.data;
                    }
                }

                const document_Res = await serverGet(`${apiConst.doclist}${_profileContext?.id}?recordid=${_profileContext?.id}&gn=${appConst.cateogries.proofUpload}`);

                const isContact = (_profile && !_profile?.parentId && _resourceList?.find((list) => list?.code === "CIN")) || false;
                const isPersonal = areValuesAssigned(_profileContext, ['dob', 'genderId', 'phone']);


                const profileValidation = {
                    pofileCompleted: isContact ? _contactContext != null && isPersonal : isPersonal,
                    idVerification: document_Res?.status == 1 && Array.isArray(document_Res?.data) && document_Res?.data?.length > 0
                }
                return profileValidation;
            } return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const getFamilyList = async () => {
        try {
            if (getAuthProfile.getProfileAuth()) {
                progParams(true);
                let _resourceList = getCurrentResource(appConst.resource.menu);



                if (!_resourceList) {
                    const res = await serverGet(`${apiConst.getResource}`);
                    _resourceList = res?.data?.resources;
                }

                //console.log(_resourceList)


                const _memberId = await getMemberId();
                let _familyMember = contextFamilyList;


                //console.log(_familyMember);
                if (!_familyMember) {
                    const _res = await serverGet(`${apiConst.familymemberget}${_memberId}`);
                    _familyMember = _res?.data;
                }

                let _patientInfo = patientInfo;

                if (!_patientInfo) {
                    const _res = await serverGet(apiConst.patientinfo);
                    _patientInfo = _res?.data;
                }


                progParams(false);

                if (_familyMember && _familyMember?.length > 0) {

                    //console.log("yes")
                    const _isFamilyMember = (
                        (Array.isArray(_familyMember) && _familyMember?.length > 0 && _familyMember?.some((list) => list?.statusId == "1" && list?.lockoutEnabled !== true)) &&
                        (_resourceList?.filter((list) => list?.code == "SK6")?.length > 0) &&
                        (!_patientInfo?.parentId)) || false;


                    if (_isFamilyMember) {
                        setisFamilyMember(true);
                    }
                    setContextFamilyList(_familyMember);

                    //console.log(_isFamilyMember);
                    return _isFamilyMember;
                } else {
                    setContextFamilyList(null);
                }
            } return false;
        } catch (e) {
            console.log(e)
        }
    }




    function areValuesAssigned(obj, keys) {
        if (obj)
            return keys.every(key => obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== "");
    }


    useEffect(() => {
        if (getAuthProfile.getProfileAuth()) {

            if (!patientInfo) {
                getPatient();
            }

            if (!profileContext) {
                getprofile();
            }




            const fetchData = async () => {
                try {
                    await getFamilyList();
                } catch (error) {
                    console.log(error);
                }
            };

            if (!contextFamilyList) {
                fetchData();
            }
        }
    }, [location]);

    //console.log(profileContext);

    return (
        <ProfileContext.Provider value={{ profileContext, updateProfile, submitProfile, imageUrl, deleteProfilePhoto, patientInfo, getPatient, checkUserProfile, contextFamilyList, isfamilyMember, getFamilyList, setContextFamilyList }}>
            {children}
        </ProfileContext.Provider>
    );
};