import React, { lazy, useEffect, useRef, useLayoutEffect } from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as bootstrap from 'bootstrap';
import { Modal } from 'bootstrap';
import { serverGet } from '../../../middleware/http';
import { apiConst, appConst, resourceConst } from '../../../services/constant/constant';
import ProgressContext from '../../../services/utils/progress';
import Tab from '../../../services/utils/tab';
import { dateTimeFormat, getAuthProfile, getSearchParams, updateSearchParams, getEncounterColor, isEnabled } from '../../../services/utils/validation';
//import PatientSummary from '../patient/PatientSummary';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import { useManageResource } from '../../../services/Context/ManageResourceContext';
import { useProfile } from '../../../services/utils/profileContext';
//import EncounterForms from './form/FormLevel/EncounterForm';

export default function DetailPage() {


    //let encounterTabs = [
    //    { component: lazy(() => import("./ConsultNote")), name: "Note", url: "./ConsultNote" },
    //    { component: lazy(() => import("./Prescription")), name: "Prescription", url: "./Prescription" },
    //    { component: lazy(() => import("./Lab")), name: "Lab", url: "./Lab" },
    //    { component: lazy(() => import("./Radiology")), name: "Radiology", url: "./Radiology" },
    //    { component: lazy(() => import("./vital/vital")), name: "Vitals", url: "./vital/vital" },
    //   { component: lazy(() => import("./form/FormLevel/EncounterForm")), name: "Form", url: "../form/FormLevel/EncounterForm" },
    //    { component: lazy(() => import("./EncounterDocuments")), name: "Documents", url: "./EncounterDocuments" },
    //]

    const accountSettingCode = "ENJN,BECON,AFCON,VCP,PTY,GWF,CPI"

    //state hooks
    const [tabs, setTabs] = useState([]);
    const [patient, setPatient] = useState()
    const [encounter, setEncounter] = useState();
    const [formShow, setFormShow] = useState(false);
    const [resourceAction, setResourceAction] = useState([]);
    const [encounterTemplate, setEncounterTemplate] = useState([]);

    const [scheduleSetting, setScheduleSetting] = useState([]);
    const { resourceList, getCurrentResourceAction, getCurrentResource, actionExists } = useManageResource();

    // ref  hooks
    const modalBackdropRef = useRef(null);

    //context hooks
    const { checkUserProfile } = useProfile();
    const { progParams } = useContext(ProgressContext);
    const { accountDate, isGWF, getAccountSetting } = useAccountSettings();
    const { getLanguageContent } = useLanguageRes();

    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const encounter_id = queryParams.get("eid");
    const m_id = queryParams.get("mid");
    const firstName = queryParams.get("fn");
    const lastName = queryParams.get("ln");
    const email = queryParams.get("em");
    const userType = queryParams.get("nw");


    // to get patient info list 
    const getPatient = async () => {
        try {
            progParams(true);
            const res = await serverGet(apiConst.patientinfo)
            if (res?.data != null) {
                setPatient(res?.data);
                return res?.data;
            }
        } catch (error) {
            console.log(error);
        } finally {
            progParams(false);
        }
    }

    // to get encounter details list 


    const getEncounter = async () => {
        try {
            progParams(true);
            const res = await serverGet(apiConst.getencounter + encounter_id);
            if (res?.status == 1 && res?.data) {
                //getTemplate(encounter_id,res?.data?.specialityId)
                updateSearchParams("spid", res?.data?.specialityId);
                setEncounter(res?.data);
                return res?.data;
            }
        } catch (error) {
            console.error(error);
        } finally {
            progParams(false);

        }

    }

    const getEncounterFlag = async (settingData) => {
        try {
            progParams(true);
            const res_enc = await getEncounter(); // to get encounter details
            if (!res_enc) return navigate(`/pagenotfound`);
            const res_patient = getPatient(); // to get patient details
            //const _accountSettings = await getAccountSetting();
            const _isProfile = await checkUserProfile();

            if (!_isProfile?.pofileCompleted) { // if profile incomplete
                return navigate(`/addprofile${encounterUrl(res_enc, res_patient?.residingStateId)}`)
            } if (isEnabled(settingData, "CPI", 1) && !_isProfile?.idVerification) { // 
                return navigate(`/idverification/upload${encounterUrl(res_enc, res_patient?.residingStateId)}`)
            } else if (res_enc?.statusId == 5) {
                return navigate(`/reason${encounterUrl(res_enc, res_patient?.residingStateId)}`)
            } else {
                setFormShow(true);   // show form list true false means show the form                                 
            }

        } catch (error) {
            console.log(error);
        } finally {
            progParams(false);
        }

    };


    function encounterUrl(res_enc, _residingid) {
        const memberid = res_enc?.providerId;
        const did = res_enc?.durationId;
        const emid = res_enc?.modeId;
        const ts = res_enc?.scheduleOn;
        const spid = res_enc?.specialityId;
        const rsid = _residingid;
        const rnote = res_enc?.note;
        const rid = res_enc?.reasonId;
        const r = res_enc?.reason;
        const scheId = res_enc?.scheduleId;
        const eid = getSearchParams("eid");
        const _fmid = getSearchParams("fmid");
        const methodid = getSearchParams("methodid");
        const _brtype = methodid == 2 ? "RW8" : methodid == 3 ? "GT6" : methodid == 1 ? "SD3" : "SD3"

        return `?memberid=${memberid}&did=${did}&emid=${emid}&ts=${ts}&spid=${spid}&rnote=${rnote}&rid=${rid}&r=${r}&eid=${eid}&scheId=${scheId}${rsid ? `&rsid=${rsid}` : ""}${_fmid ? `&fmid=${_fmid}` : ""}${res_enc?.statusId == 5 ? "&isrp=t" : ""}&brtype=${_brtype}${res_enc?.serviceOptionId ? `&speicalityservice=${res_enc?.serviceOptionId}` : ""}`;

    }

    const getSetting = async () => {
        try {
            const res = await serverGet(`${apiConst.getaccountsettings}/-/${encodeURIComponent(accountSettingCode)}`);

            if (res?.status == 1 && res?.data) {
                getEncounterFlag(res?.data);
                setScheduleSetting(res?.data);
            }
        } catch (error) {
            console.error(error);
        } 

    };

    //TO OPEN THE JOIN CAL URL IN NEW TAB

    const joinCallButton = (url) => {
        window.open(url, '_blank');

    }


    const getTemplate = async () => {
        try {

            const eid = getSearchParams("eid");
            const res = await serverGet(`${apiConst.encountertemplate}?eid=${eid}&aid=${2}`)

            if (res?.data && res?.status == 1) {
                setEncounterTemplate(res?.data);
                return res?.data;
            }

        } catch (e) {
            console.error(e);
        }
    }

    //to call the  flag lsit userType 1 = new user redirects to sign up , userType 0 = existing user redirects to sign in

    useLayoutEffect(() => {
        document.title = 'Visit detail';
        const lNavbarDiv = document.getElementById("lnavbardiv");
        if (m_id && m_id != '') {
            if (userType == '1') {
                if (getAuthProfile.getProfileAuth()) {
                    getSetting();
                } else {
                    if (lNavbarDiv) {
                        lNavbarDiv.classList.add("d-none");
                    }
                    window.location.href = `/security/signup?eid=${encounter_id}&mid=${m_id}&fn=${firstName}&ln=${lastName}&em=${email}&isrp=t`;
                }
            } else if (userType == '0') {
                if (getAuthProfile.getProfileAuth()) {
                    getSetting();
                } else {
                    if (lNavbarDiv) {
                        lNavbarDiv.classList.add("d-none");
                    }
                    window.location.href = `/security/login?eid=${encounter_id}&isrp=t`;
                }
            }
        } else {
            if (getAuthProfile.getProfileAuth()) {
                getSetting();
            } else {
                window.location.href = `/security/login?eid=${encounter_id}&isrp=t`;
            }
        }
    }, []);



    useEffect(() => {
        getTemplate();
    }, [])


    useEffect(() => {
        const resourceLists = async () => {
            const _resourceList = getCurrentResource(appConst.resource.moduleMenu, "CNS");
            if (encounterTemplate?.length > 0 && _resourceList?.length > 0) {
                const temp = [];
                const templates = encounterTemplate?.filter(list => _resourceList?.some(item => list?.referenceId == item?.id) || list?.isForm);
                templates.forEach((list) => {
                    if (list.isForm) {
                        list.formUrl = './form/FormLevel/EncounterForm'
                    }
                });

                await templates?.forEach((nav) => {
                    if (nav?.isForm) {
                        temp.push({ ...nav, id: nav?.referenceId, component: lazy(() => import(`${nav?.formUrl}`)), name: nav?.resourceName, url: nav?.formUrl })
                    } else {
                        const _resource = _resourceList?.find((list) => list?.id == nav?.referenceId);
                        temp.push({ ...nav, ..._resource, id: nav?.referenceId, component: lazy(() => import(`${_resource?.url}`)), name: nav?.resourceName, url: _resource?.url })
                    }
                })
                setTabs(temp);
            }
        };

        const actionList = () => {
            const _actions = getCurrentResourceAction("CSS", "CNS");
            setResourceAction(_actions);
        };
        resourceLists();
        actionList();
    }, [resourceList, encounterTemplate]);


    // Close the Bootstrap modal

    useEffect(() => {
        const bsModalBackdrop = modalBackdropRef.current;
        return () => {
            const bsModal = bootstrap.Modal.getInstance(bsModalBackdrop);
            if (bsModal) {
                bsModal.hide();
            }
        };
    }, []);


    return (
        <>
            <div>
                {(encounter && actionExists(resourceAction, resourceConst?.actions?.view)) && (
                    <div>

                        <div className="card border-0 mb-3">
                            <div className="card-body">
                                <div className="d-flex">
                                    <h6 className="flex-grow-1 card-title text-uppercase fw-bold ">
                                        {getLanguageContent("csltion")}
                                    </h6>
                                </div>
                                <div className="card my-3">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h6 className="card-title">
                                                    <span className="text-muted">{encounter?.patientName}</span>
                                                    <span> | </span>
                                                    <span className="fw-bold">{encounter?.encounterNo}</span>
                                                    <span> | </span>
                                                    <span className="text-muted">{encounter?.consultationType} </span>
                                                    <span className={`badge rounded-pill ${getEncounterColor(encounter?.statusId)}`}>{encounter?.encounterStatus}</span>
                                                </h6>

                                                <small className="text-muted">{encounter?.specialityName}</small>

                                                {(encounter?.reasonCode && encounter?.reasonCode?.trim() !== "OTH") && (
                                                    <small className="text-muted"> | {encounter?.reason}</small>
                                                )}
                                            </div>
                                            <div className="flex-shrink-0">
                                                {(encounter?.encounterModeCode == appConst.encounterModes.online || encounter?.encounterModeCode == appConst.encounterModes.phoneOnline) &&
                                                    !isGWF &&
                                                    encounter?.joinUrl &&
                                                    scheduleSetting?.find(list => list?.settingCode == "VCP" && list?.value != "NONE") &&
                                                    enableBtn(encounter?.scheduleOn, scheduleSetting, encounter?.statusId) ?
                                                    <span className={`me-2 btn btn-primary btn-sm rounded-pill`} onClick={() => joinCallButton(encounter?.joinUrl)} >{getLanguageContent("jncal")}</span>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            {encounter?.note && <small className="text-muted">Note: {encounter?.note}</small>}
                                        </div>
                                        {(encounter.statusId == appConst.encStatusId.cancelled && encounter?.cancellationReason && encounter?.cancellationReason?.trim()) && (
                                            <div>
                                                <small className="text-muted">Reason for cancellation/denial </small><small className="fw-bold">{encounter?.cancellationReason}</small>
                                            </div>
                                        )}
                                        <hr className="text-muted"></hr>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div>
                                                    <span className="text-muted">{getLanguageContent("pvdr")}</span> - <span className="fw-bold">{encounter?.providerName}</span>
                                                </div>
                                                <div className="text-muted">
                                                    <svg className="me-1" width="16" height="16" role="img"><use xlinkHref={encounter?.encounterModeCode == "INP" ? "#svg_human" : encounter?.encounterModeCode == "TEL" ? "#svg_teleconsult" : encounter?.encounterModeCode == "PHN" || encounter?.encounterModeCode == "PHT" ? "#svg_phn-consult" : ""} /></svg>
                                                    {encounter?.encounterMode} | {encounter?.duration}
                                                </div>
                                            </div>
                                            <div className="col-md-6 text-muted text-md-end">
                                                {
                                                    encounter?.scheduleOn != null ?
                                                        <span className="d-block">{getLanguageContent("appton")} {dateTimeFormat(encounter?.scheduleOn, `${accountDate?.value} hh:mm A`)} </span>
                                                        : <></>
                                                }
                                                <span>{getLanguageContent("cston")} {dateTimeFormat(encounter?.encounterOn, `${accountDate?.value} hh:mm A`)} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {tabs?.length > 0 && (
                    <div className="row">
                        <Tab tabs={tabs}
                            encounter={encounter}
                            accountDate={accountDate}
                            patient={patient}
                        />
                    </div>
                )}
            </div>
        </>
    )
}


function enableBtn(date, enableJoin, status) {

    if (status != 1 && status != 2 && status != 3) {
        return false;
    }
    // console.log(enableJoin)
    let ENJN = []
    let BECON = []
    let AFCON = []

    // Convert the given date string to a JavaScript Date object
    //const givenDateTime = new Date(date);

    const convertDatetime = (datetime) => {
        return new Date(datetime + 'Z');
    };

    const givenDateTime = convertDatetime(date);
    // Get the current time
    const currentTime = new Date();
    // Calculate the time difference in minutes and days
    const timeDifferenceInMillis = givenDateTime - currentTime;
    const minuteDifference = Math.floor(timeDifferenceInMillis / (1000 * 60));
    const dayDifference = Math.floor(timeDifferenceInMillis / (1000 * 60 * 60 * 24));
    const absDayDifference = Math.abs(dayDifference);

    if (enableJoin?.length > 0) {
        ENJN = enableJoin?.filter((list) => list?.settingCode === 'ENJN')
        BECON = enableJoin?.filter((list) => list?.settingCode === 'BECON')
        AFCON = enableJoin?.filter((list) => list?.settingCode === 'AFCON')
    }

    //console.log(absDayDifference, minuteDifference, date)
    //console.log(currentTime, minuteDifference, givenDateTime)


    if (ENJN[0]?.value == "1") {
        //console.log("in")
        //minuteDifference is less than or equal to the value && minuteDifference is greater than or equal to zero. & to enable join btn before the consulation time  
        if (minuteDifference <= BECON[0].value && minuteDifference >= 0) {
            // console.log("true")

            return true;
        }
        //absDayDifference is greater than or equal to zero & less than the value & less than the current time. &  how long that join btn should be visible after consultation
        else if (absDayDifference >= 0 && absDayDifference <= AFCON[0].value && givenDateTime.getTime() < currentTime.getTime()) {
            return true;
        }
        else {
            return false;
        }
    } else if (ENJN[0]?.value != "1") {
        //  checks  the time represented by the current time is greater than or equal to the given date in milliseconds. & to show if appointment time is exceed & status is not completed
        //if (currentTime.getTime() >= givenDateTime.getTime() && status != "3") {
        if (currentTime.getTime() >= givenDateTime.getTime()) {
            return true;
        } else {
            return false;
        }
    }
}

//<div ref={modalBackdropRef} className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
//    <div className="modal-dialog modal-dialog-centered">
//        <div className="modal-content">
//            <div className="modal-header d-flex flex-column ">
//                <h4 className="modal-title" id="staticBackdropLabel">{getLanguageContent("atton")}</h4> <br />
//            </div>
//            <div className="modal-body">
//                <p className='text-center'>{getLanguageContent("flinfo")}</p>
//                {flagRes && flagRes?.length > 0 && (
//                    flagRes?.map((list, index) => (
//                        <div key={index} className={`card mb-3 ${list?.flag ? "border-success" : "border-danger"}`}>
//                            <div className="card-body">
//                                <div className="d-flex">
//                                    <div className="align-self-center">
//                                        <svg width="48" height="48" role="img">
//                                            {
//                                                list?.flag ?
//                                                    <use xlinkHref="#svg_circle_success"></use> : <use xlinkHref="#svg_circle_danger"></use>
//                                            }
//                                        </svg>
//                                    </div>
//                                    <div className="flex-grow-1 ps-3">
//                                        <h5 className="card-title">{list?.title}</h5>
//                                        <p className="card-text">{list?.description}</p>
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    ))
//                )}
//            </div>
//            <div className="modal-footer d-flex justify-content-end">
//                <button data-bs-dismiss="modal" type="button" onClick={confirmationDialog} className="btn btn-primary">{getLanguageContent("ok")}</button>
//            </div>
//        </div>
//    </div>
//</div>
