import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './route/AppRoutes';
import { serverGet } from './middleware/http';
import { apiConst } from './services/constant/constant';
import { useProfile } from './services/utils/profileContext';
import { ImageCropContextProvider } from './services/utils/imageCrop';
import Layout from './theme/default/Layout';
import { DoctorProvider } from './services/Context/DoctorContext';
import { ecnSession, getAuthProfile } from './services/utils/validation';
import { EncounterProvider } from './services/Context/JoinCall';
import { LanguageResProvider } from './services/Context/LanguageContext';

export default function App() {
    const [islogin, setIslogin] = useState((/security\/(forgotpassword|login|resetpassword)/gm.test(window.location.pathname.toLowerCase())));
    const [profile, setprofile] = useState();
    const [isDocViewer, setIsDocViewer] = useState(false);

    const [account, setAccount] = useState(null);
    const { updateProfile } = useProfile();
    const location = useLocation();

    const initAccount = async () => {
        try {
            const res = await serverGet(`${apiConst.gethost}`);
            if (res?.status == 1) {
                setAccount(res?.data);
                let codes = (res?.data?.portalSettings?.filter(obj => obj.code === "THM")[0]?.value || "")?.split("~");
                if (codes?.length >= 4) {
                    var styles = {
                        "primary-color": codes[0],
                        "accent-color": codes[1],
                        "primary-color-rgb": codes[2],
                        "accent-color-rgb": codes[3],
                        "bs-body-font-family": !codes[4] ? "'Nunito', sans-serif" : codes[4].split("|")[1],
                    };
                    Object.keys(styles).forEach(function (key) {
                        document.documentElement.style.setProperty('--' + key, styles[key]);
                    })
                    var url = !codes[4] ? "https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap" : codes[4].split("|")[0]; 

                    loadCSS(url);
                }
                //console.log(res)
            }
        } catch (e) {
            console.log(e);
        }

    }


    const loadCSS = (href) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        document.head.appendChild(link);
    };

     
    const getprofile = async () => {
        if (getAuthProfile.getProfileAuth()) {
            // window.alert("calling")
            try {
                const res = await serverGet(apiConst.getuserprofile);
                if (res?.status == 1 && res?.data) {
                    //contact
                    const res_profile = JSON.parse(res?.data?.profile)
                    const res_contact = JSON.parse(res?.data?.contact)

                    const response = {
                        resProfile: res_profile?.data,
                        resContact: res_contact?.data,
                    }
                    updateProfile(response);
                    setprofile(response);
                    ecnSession(res_profile?.data?.id, "mid"); // {mid} user memberid
                    localStorage.setItem("memberId", res_profile?.data?.id);
                    getAuthProfile.saveProfileAuth();
                }
            } catch (error) {
                console.log(error);
            }
        }
    }


    const getResCode = async () => {
        try {
            const res = await serverGet(apiConst.getresponsecode + "/en-us");
            if (res?.data != null && res?.status == 1) {
                localStorage.setItem("errorMsg", JSON.stringify(res?.data));              
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getAuth = async () => {
        try {
            const res = await serverGet(apiConst.getauthprofile);
            if (res?.status == 1) {
                if (res?.data?.auth) {
                    getAuthProfile.saveProfileAuth();
                } else {
                    getAuthProfile.clearProfileAuth();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                //await getAuth();           
                await getResCode();
                await initAccount();
                if (getAuthProfile.getProfileAuth() && !window.location.pathname.includes("security"))
                    await getprofile();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (location.pathname.includes("security")) {
            setIslogin(true);
        } else {
            setIslogin(false);
        }

        if (location.pathname.includes("docviewer") || location.pathname.includes("pagenotfound") ) {
            setIsDocViewer(true);
        } else {
            setIsDocViewer(false);
        }

    }, [location]);

    return (
        <ImageCropContextProvider>
            <DoctorProvider>
                <EncounterProvider>
                    <LanguageResProvider>
                        <Layout islogin={islogin} isViewer={isDocViewer} account={account}>
                            <Routes>
                                {AppRoutes?.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                        </Layout>
                    </LanguageResProvider>
                </EncounterProvider>
            </DoctorProvider>
        </ImageCropContextProvider>
    )
}






