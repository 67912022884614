import React, { useContext, useState } from "react"
import { dateFormat, getHtmlContent, getMemberId, getSearchParams } from "../../../../../services/utils/validation";
import { serverGet } from "../../../../../middleware/http";
import { apiConst } from "../../../../../services/constant/constant";
import { useEffect } from "react";
import { getHeadingClass } from "../customForm";
import { useAccountSettings } from "../../../../../services/Context/AccSettingContext";
import ProgressContext from "../../../../../services/utils/progress";

export default function SummaryForm({ selectedFormId, handleFormTitle }) {

    const [formData, setFormData] = useState([]);
    const { accountDate } = useAccountSettings();
    const { progParams } = useContext(ProgressContext);


 
    const getFormFields = async (id) => {
        try {
            progParams(true);
            const _memberId = await getMemberId();
            let encounter_id = getSearchParams("eid");
            let fmid = getSearchParams("fmid");
            const res = await serverGet(`${apiConst.getformlist}${id}?mid=${fmid ? fmid :_memberId}${encounter_id ? `&eid=${encounter_id}` : ""}`)
            progParams(false);
            if (res?.status == 1 && res?.data) {
                setFormData(res?.data)
            }
        } catch (e) {
            console.log(e)
        }
    }



    useEffect(() => {
        //getForms();
        if (selectedFormId) {
            getFormFields(selectedFormId);

        }
    }, [])

    return (
        <>
            {(Array.isArray(formData) && formData.some(list => list.value && list.value?.trim() != "")) && (
                <>
                    {handleFormTitle()}
                    <h4>{formData[0]?.formTitle}</h4>
                    {formData?.map((list, index, array) => (
                        <>
                            {(list?.controlId == 1 && list?.value) && (
                                <RenderQuestionAndAns question={list?.clientTitle} answer={list?.value} />
                            )}

                            {(list?.controlId == 2 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderCheckBox arr={array} id={list.fieldId} title={list?.clientTitle} />
                                </>
                            )}

                            {list?.controlId == 3 ?
                                <div className="my-4">
                                    <p className='fw-bold'>{list?.clientTitle}</p>
                                </div> : ''
                            }

                            {list?.controlId == 4 ?
                                <div className="border border-2 rounded rounded-2 p-4">
                                    <div className={`${list?.styleId == '3' ? "scroll-list-container bg-light text-dark p-3" : ''}`} dangerouslySetInnerHTML={{ __html: list?.fieldOption }}>
                                    </div>
                                </div>
                                : ""
                            }

                            {(list?.controlId == 5 && list?.value) && (
                                <>
                                    <RenderQuestionAndAns question={list?.clientTitle} answer={dateFormat(list?.value, accountDate?.value)} />
                                </>
                            )}

                            {(list?.controlId == 6 && list?.value && array[index - 1]?.fieldId != list?.fieldId) && (
                                <>
                                    <RenderRadio arr={array} id={list.fieldId} />
                                </>
                            )}

                            {(list?.controlId == 7 && list?.value) && (
                                <>
                                    <RenderQuestionAndAns question={list?.clientTitle} answer={list?.value} />
                                </>
                            )}

                            {(list?.controlId == 8 && list?.value) && (
                                <RenderYearAndMonth question={list?.clientTitle} answer={list?.value} />
                            )}

                            {
                                (list?.controlId == 9 || list?.controlId == 10 || list?.controlId == 11) ?
                                    <div key={list?.id} className="mb-2">
                                        <span className={`${getHeadingClass(list?.controlId)} fw-normal`}>{list?.clientTitle}</span>
                                    </div> : ''
                            }

                            {(list?.controlId == 12 && list?.clientTitle && list?.clientTitle?.trim()) ?
                                <div key={list?.id} className="mb-2" dangerouslySetInnerHTML={{ __html: getHtmlContent(list?.clientTitle) }}>
                                </div> : ''
                            }

                        </>
                    ))}
                    <hr />
                </>
            )
            }
        </>
    )
}


const RenderYearAndMonth = ({ question, answer }) => {

    const [year, month] = answer?.split("~")

    return (
        <>
            <div className="mb-2">
                <span>{question}</span><br />
                <span className="fw-bold">{year ? `${year} Year` : ""} {month ? `${month} Month` : ""}</span>
            </div>
        </>
    )
}



const RenderCheckBox = ({ arr, id, title }) => {
    const list = arr?.filter(item => item.fieldId == id);

    if (!list?.length) return null;

    const { clientTitle, value } = list[0];
    const options = value.split("~").map(child =>
        list.find(v => v.fieldOptionId == child)?.fieldOption
    ).filter(Boolean).join(', ');

    return (
        <RenderQuestionAndAns question={clientTitle} answer={title && options ? options : options ? "Agreed":""} />
    );
};


const RenderRadio = ({ arr, id }) => {
    const list = arr?.filter(item => item.fieldId == id);

    if (!list?.length) return null;
    const { clientTitle, value } = list[0];


    return (
        <RenderQuestionAndAns question={clientTitle} answer={list.find(v => v.fieldOptionId == value)?.fieldOption} />

    )
}


const RenderQuestionAndAns = ({ question, answer }) => {
    return (
        <>
            {answer && (
                <div className="mb-2">
                    <span>{question}</span><br />
                    <span className="fw-bold">{answer}</span>
                </div>
            )}
        </>
    )
}


