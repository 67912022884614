import React, { useState, useRef, useContext } from 'react';
import { useEffect } from 'react';
import DOMPurify from 'dompurify';
import he from "he";
import VoiceRecorder from './AudioRecorder';
import AddMessage from './AddMessage';
import ProgressContext from '../../../services/utils/progress';
import { serverGet } from '../../../middleware/http';
import { apiConst } from '../../../services/constant/constant';
import { dateAndTime, dateTimeFormat } from '../../../services/utils/validation';
import { useAccountSettings } from '../../../services/Context/AccSettingContext';
import { useLanguageRes } from '../../../services/Context/LanguageContext';
import ActionStatus from '../../../services/utils/actionStatus';
//import MessageList from './messageList';
//import AudioRecorder from './AudioRecorder';



export default function Message({ msgId, handleBack, list, getInboxList, edit }) {


    const [messages, setMessages] = useState([]);
    const [messageSubject, setMessageSubject] = useState("");
    const { progParams } = useContext(ProgressContext);

    const { getLanguageContent } = useLanguageRes();

    //const backButton = () => {
    //    handleBack();
    //}


    const getList = () => {
        setMessages([]);
        setMessageSubject("");
        progParams(true);
        serverGet(`${apiConst.getMessage}/${msgId}`).then((res) => {
            progParams(false)
            try {
                if (res?.data && res?.status == 1) {
                    setMessages(res?.data);
                    setMessageSubject(res?.data[0]?.subject);
                    getInboxList();

                }
            } catch (e) {
                console.log(e);
            }
        })
    }




    useEffect(() => {
        if (msgId) {
            // console.log(msgId);
           



            const fetchData = async () => {
                await getList();
               // await getInboxList();
            }
            fetchData();

        }

    }, [msgId]);

    return (
        <>

            <div className="card border-0 h-100 ">
                <div className="card-body ">
                    {msgId ?
                        <>
                            <AddMessage msgId={msgId} list={messages} level="edit" messageSubject={messageSubject} getList={getList} fieldDisabled={true} backButton={handleBack} edit={edit} />

                            <div>
                                <MessageContainer list={messages} />
                            </div>

                        </> :
                        <>
                            <div className="d-flex align-items-center justify-content-center h-100">
                                {/*<h3>{getLanguageContent("msgslt")}</h3>*/}
                                {list != "loading" && (
                                    <ActionStatus description={`${getLanguageContent(list?.length < 0 || !list ? "msgemp" : "msgslt")}`} button={""} imageid="#svg_failed" onsyncchange={""} type="search" descriptionClass={"fw-normal"} />
                                )}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}


//<>
//    {(list != "loading" && (!list || list?.length < 0)) ?
//        <ActionStatus description={`${getLanguageContent("msgemp")}.`} button={""} imageid="#svg_failed" onsyncchange={""} type="search" descriptionClass={"fw-normal"} />
//        : ""
//    }
//</>

const MessageContainer = ({ list }) => {

    const { accountDate } = useAccountSettings();


    const getHtmlContent = (data) => {
        const cleanHtml = DOMPurify.sanitize(data);
        const decodedHTML = he.decode(cleanHtml);

        return decodedHTML;
    }
    return (
        <>
            {(Array.isArray(list) && list?.length > 0) && (
                list?.map((item) => (
                    <div className="border p-2 mb-3 rounded">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                        <img className="rounded-circle border" width="40" height="40" src={!item?.photoPath ? require('../../../asset/images/blankuser.png') : "doc/getimage/true?url=" + encodeURIComponent(item?.photoPath)} alt={"user_img"} alt="User Profile" />
                                        <h6 className="ms-2 d-inline">{item?.sender}</h6>
                                    </div>
                                    <div className="ms-auto">
                                        <h6 className="d-inline d-md-none ms-auto">{dateAndTime(item?.sentOn, accountDate?.value)}</h6>
                                        <h6 className="d-none d-md-inline ">{dateTimeFormat(item?.sentOn, `${accountDate?.value} hh:mm A`)}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-secondary">
                                <div className="textfield-wrap" dangerouslySetInnerHTML={{ __html: getHtmlContent(item?.content) }}>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    )
}

//<div className="d-block d-xl-none text-end">
//    <button onClick={() => backButton()} type="button" className="btn btn-outline-primary">
//        Back
//    </button>
//</div>

//<div className="card border">
//    <div className="card-body ">

//    </div>
//</div>
//<div className="mt-5 rounded p-2">
//    <div className="d-flex align-items-center mb-3">
//        <div>
//            <img className="rounded-2 profile-pic-upload" width="100%" src={"https://cdn-icons-png.flaticon.com/512/3953/3953004.png"} alt="User Profile" />
//        </div>
//        <div>
//            <span className="fw-bold">{message?.name}</span>
//        </div>
//    </div>
//    <div>
//        <div className="fw-bold">
//            Subject: <span>{message?.company?.catchPhrase}</span>
//        </div>
//    </div>
//</div>


//<div className="message-container">
//    {audioFile &&
//        <div className="p-3 rounded-4 mb-3" >
//            <div className="d-flex align-items-center">
//                {/*<div className="">*/}
//                {/*    <button onClick={playAudio} type="button" className="btn btn-info rounded-circle position-relative">*/}
//                {/*        {isPlaying ?*/}
//                {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pause-circle" viewBox="0 0 16 16">*/}
//                {/*                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />*/}
//                {/*                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z" />*/}
//                {/*            </svg>*/}
//                {/*            :*/}
//                {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-play-circle" viewBox="0 0 16 16">*/}
//                {/*                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />*/}
//                {/*                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />*/}
//                {/*            </svg>*/}
//                {/*        }*/}
//                {/*    </button>*/}
//                {/*</div>*/}
//                <audio src={URL.createObjectURL(audioFile)} controls="controls" />
//                {/*{playAnimation &&*/}
//                {/*    <div className id="sound-wave">*/}

//                {/*    </div>*/}
//                {/*}*/}
//            </div>
//        </div>
//    }
//</div>

//<div className="position-relative h-100 ">
//    <div className="input-group mt-5">
//        <input className="form-control form-control-lg rounded" type="text" placeholder="Message" aria-label="Recipient's username" aria-describedby="basic-addon2" />
//        <div style={{ position: "absolute", top: "25%", right: "70px" }}>
//            <VoiceRecorder setAudioFile={setAudioFile} />
//        </div>
//        <div style={{ position: "absolute", top: "25%", right: "120px" }}>
//            <div
//                //onDragOver={(e) => e.preventDefault()}
//                //onDrop={handleDrop}
//                onClick={(e) => handleClick(e)}
//            >
//                <input
//                    className="d-none"
//                    accept=".png, .jpg, .jpeg, .gif, application/pdf"
//                    type="file"
//                    ref={fileInputRef}
//                    multiple
//                    onChange={handleFileSelect}
//                />
//                <button type="button" className="btn btn-primary rounded-circle position-relative">
//                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16">
//                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
//                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
//                    </svg>
//                    {selectedFiles?.length > 0 &&
//                        <span className="position-absolute top-0 start-100 translate-middle badge text-bg-dark rounded-pill">
//                            {selectedFiles?.length}
//                        </span>
//                    }
//                </button>
//            </div>
//        </div>
//        <span className="input-group-text border-0  bg-transparent" id="basic-addon2">
//            <button type="button" className="btn btn-primary rounded-circle">
//                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
//                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
//                </svg>
//            </button>
//        </span>
//    </div>
//</div>