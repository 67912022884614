import React, { useContext, useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SvgIcon from '../icon/iconSvg';
import NavMenu from './NavMenu';
import Footer from './Footer';
//import { serverGet } from '../../middleware/http';
//import { apiConst } from '../../services/constant/constant';
import ProgressContext from '../../services/utils/progress';
import { dateFormat, ecnSession, getAuthProfile, validateImageSize } from '../../services/utils/validation';
import { useProfile } from '../../services/utils/profileContext';
import ImageCropContext from '../../services/utils/imageCrop';
import NotifyContext from '../../services/utils/notify';
import { useAccountSettings } from '../../services/Context/AccSettingContext';
import { apiConst, appConst, resourceConst } from '../../services/constant/constant';
import { serverGet } from '../../middleware/http';
import { useManageResource } from '../../services/Context/ManageResourceContext';


const Layout = (props) => {
    //const loactionPaths = ["/dashboard", "/visit/detail", "/profile", "/changepassword", "/profile/idinfo","/vitals", "/insurance", "/module/transaction", "/module/forms", "/message", "/insuranceedit","/insuranceadd" , "/vitals", "/insurance",];
    const loactionPaths = ["/dashboard", "/visit/detail", "/profile", "/alldocuments", "/vitals", "/insurance", "/forms", "/message", "/familymember"];
    const location = useLocation();
    const navigate = useNavigate();
    const [profileResAction, setProfileResAction] = useState([]);
    const [getUserProfile, setUserProfile] = useState({ photoPath: null, dob: null, genderId: "" });
    const [file, setFile] = useState(null);
    const [photoURL, setPhotoURL] = useState('');
    //const [getSettingOption, setSettingOption] = useState([]);
    const [cropedURL, setCropedURL] = useState('');
    const [openCrop, setOpenCrop] = useState(false);
    const [setting, setSetting] = useState(false);
    const [resources, setResources] = useState(null);

    const { notifyToast } = useContext(NotifyContext);
    const { progParams } = useContext(ProgressContext);
    const { profileContext, imageUrl, deleteProfilePhoto, patientInfo } = useProfile();
    const { showCropImage } = useContext(ImageCropContext);
    const { accountDate, isGWF, account } = useAccountSettings();
    const { getCurrentResource, resourceList, getCurrentResourceAction, actionExists } = useManageResource();
    const profileupload = useRef(null);
    const aspect = 1;
    //const accountSettingCode = 'CPI';

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);



   // console.log(patientInfo)

    const handleOutsideClick = (e) => {

        //if (menuRef.current !== e.target && buttonRef.current !== e.target)
        //    setIsNavCollapsed(true);
        if (menuRef.current && !menuRef.current.contains(e.target) && e.target !== buttonRef.current)
            setIsNavCollapsed(true);
    };

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    getUserProfile.photoPath = cropedURL == "" ? getUserProfile.photoPath : cropedURL;

    const resProfile = props?.profile?.resProfile

    function CheckPath(pathnameToCheck) {
        if (location.pathname === pathnameToCheck) {
            return "active";
        }
        return "";
    }

    const logout = () => {
        progParams(true);
        serverGet(apiConst.authlogout).then((res) => {
            try {
                if (res?.messageCode && res?.status == 1) {
                    localStorage.removeItem('langResource');
                    localStorage.removeItem('memberId');
                    ecnSession("clear");
                    getAuthProfile.clearProfileAuth();
                    sessionStorage.removeItem('redirectUrl');
                    setTimeout(() => {
                        window.location.href = "/security/login"

                        return navigate(`/security/login`, { replace: true });

                        progParams(false);
                    }, 1500)
                } else {
                    notifyToast(200);
                    progParams(false);
                }
            } catch (e) {
                console.log(e)
            }
        })
    }



    const handleChange = (e) => {
        var isValidImg = validateImageSize(e.target?.files[0], 5);
        if (isValidImg == "succeed") {
            const file = e.target?.files[0];
            var photoURL = URL.createObjectURL(file);
            showCropImage({ ...{ photoURL, setOpenCrop, setPhotoURL, setFile, setCropedURL, aspect } });
            if (file) {
                setFile(file);
                setCropedURL(null)
                setPhotoURL(URL.createObjectURL(file));
                setOpenCrop(true);
            }
        } else {
            notifyToast(isValidImg);
        }

    };



    const handleDelete = () => {
        const confirmed = window.confirm('Are you sure you want to delete?');
        if (confirmed) {
            deleteProfilePhoto();
        }
    }


    //const devModules = [
    //    {
    //        name: "Vitals",
    //        urlPath: "/vitals",
    //        svgicon: "#vital_icon"
    //    },

    //    //{
    //    //    name: "Insurance",
    //    //    urlPath: "/insurance",
    //    //    svgicon: "#user_insurance"
    //    //},


    //    //{
    //    //    name: "Vitals",
    //    //    urlPath: "/vitalform",
    //    //    svgicon: "#vital_icon"
    //    //},       
    //    //{
    //    //    name: "Forms",
    //    //    urlPath: "/module/forms",
    //    //    svgicon: "#custorm_forms"
    //    //},
    //    //{
    //    //    name: "Transactions",
    //    //    urlPath: "/transaction",
    //    //    svgicon: "#user_transaction"
    //    //},
    //    //{
    //    //    name: "Message",
    //    //    urlPath: "/message",
    //    //    svgicon: "#user_message"

    //    //},
    //]


    useEffect(() => {
        if (getAuthProfile.getProfileAuth()) {
            const _resourceList = getCurrentResource(appConst.resource.menu);
            setResources(_resourceList);
            const actionList = () => {
                const _actions = getCurrentResourceAction("PIN", "EQ7");
                setProfileResAction(_actions);
            };
            actionList();
        }
    }, [resourceList])


    //console.log(resourceList, profileResAction);
    // console.log(resourceList, profileResAction, profileContext);

    return (
        <>
            <SvgIcon />
            {props?.account?.portalSettings?.length > 0 && (
                <>
                    {!props.isViewer && <NavMenu cropedURL={cropedURL} setting={setting} isLogin={props.islogin} profile={profileContext} account={props?.account} />}
                    {/*<div className="bg-light container-xxl p-1 p-md-4 main-container">*/}
                    <div className={`bg-light ${loactionPaths?.includes(location.pathname) ? "" : "container-xxl p-1 p-md-4"} main-container`}>
                        {loactionPaths?.includes(location.pathname) ?
                            <div className="d-flex flex-md-row flex-column">
                                <div id='lnavbardiv' className={`l-navbar flex-shrink-0 p-3 align-self-stretch`}>
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <label htmlFor="flePhoto" className="border border-light-subtle rounded-3 position-relative profile-pic-upload py-4">
                                                <input ref={profileupload} onClick={(event) => {
                                                    event.target.value = ''
                                                }} autoFocus={true} type="file" accept="image/png, image/jpeg, image/gif" className="form-control" onChange={handleChange} />
                                                <img loading="lazy" className="rounded-2 img-fluid" width="100%" src={imageUrl ? imageUrl : profileContext?.resProfile?.photoPath ? "doc/getimage/true?url=" + encodeURIComponent(profileContext?.resProfile?.photoPath) : require('../../asset/images/blankuser.png')} alt="User Profile" />


                                                <div className="dropdown">
                                                    <span type="button" data-bs-toggle="dropdown" aria-expanded="false" className="position-absolute  bottom-0 start-100 translate-middle badge border border-light rounded-circle bg-dark p-1 me-2 image-croper-camera-icon">
                                                        <svg width="16" height="16" role="img" fill="currentColor">
                                                            <use xlinkHref="#edit_icon_pencil"></use>
                                                        </svg>
                                                    </span>
                                                    <ul className="dropdown-menu">
                                                        <li onClick={() => {
                                                            if (profileupload.current) {
                                                                profileupload.current.click();
                                                            }
                                                        }}>
                                                            <a className="dropdown-item" >Upload</a>

                                                        </li>
                                                        {(profileContext?.resProfile?.photoPath && actionExists(profileResAction, resourceConst?.actions?.edit)) && (
                                                            <li onClick={handleDelete}>
                                                                <a className="dropdown-item">Remove</a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="flex-grow-1 position-relative">
                                            {/*{(resProfile?.firstName && resProfile?.lastName) &&*/}
                                            {/*    <h5>{`${resProfile?.firstName} ${resProfile?.lastName}`}</h5>*/}
                                            {/*}*/}
                                            {/*<div className='d-flex align-items-center'>*/}
                                            {/*    {(resProfile?.dob || profileContext?.resProfile?.dob) && (*/}
                                            {/*        <>*/}
                                            {/*            <svg width="18" height="18" role="img"><use xlinkHref="#svg_cake" /></svg>*/}
                                            {/*            <span className="text-muted ms-2">{dateFormat(profileContext?.resProfile?.dob ? profileContext?.resProfile?.dob : resProfile?.dob, accountDate?.value)}</span>*/}
                                            {/*        </>*/}
                                            {/*    )}                                                                                                   */}
                                            {/*</div>*/}
                                            {(profileContext?.resProfile?.firstName && profileContext?.resProfile?.lastName) && (
                                                <h5>{`${profileContext?.resProfile?.firstName} ${profileContext?.resProfile?.lastName}`}</h5>
                                            )}
                                            <div className="d-flex align-items-center">
                                                {(profileContext?.resProfile?.dob) && (
                                                    <>
                                                        <svg width="18" height="18" role="img"><use xlinkHref="#svg_cake" /></svg>
                                                        <span className="text-muted ms-2">{dateFormat(profileContext?.resProfile?.dob, accountDate?.value)}</span>
                                                    </>
                                                )}
                                            </div>
                                            <div className="position-absolute end-0 d-block d-md-none" style={{ top: "25%" }}>
                                                <button class="btn btn-light" ref={buttonRef} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                                    {isNavCollapsed ? <svg width="12" height="7"><use xlinkHref="#svg_arrowdown"></use> </svg>
                                                        : <svg width="7" height="12"><use xlinkHref="#svg_arrowright"></use> </svg>
                                                    }
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div use={menuRef} className={`${isNavCollapsed ? 'collapse' : ''} nav_list navbar-collapse mt-4`} id="navbarSupportedContent">

                                        {(Array.isArray(resources) && resources.length > 0) && (
                                            <>
                                                {resources?.filter((list) => patientInfo?.parentId ? list?.code != "SK6" : true )?.map((menu, i) => (
                                                    <>
                                                        <Link onClick={() => setIsNavCollapsed(true)} className={`nav_link  ${CheckPath(menu?.url)}  ${location.pathname === '/visit/detail' && i == 0 ? "active " : ''} `} to={`${menu?.url}?rcode=${menu?.code}`} key={i}>
                                                            <svg className="nav_icon" width="24" height="24" role="img"><use xlinkHref={menu?.icon} /></svg>
                                                            <span className="nav_name">{menu?.title}</span>
                                                        </Link>
                                                    </>
                                                ))}
                                            </>
                                        )}

                                        {/*<Link onClick={() => setIsNavCollapsed(true)} className={`nav_link  ${CheckPath("/dashboard")}  ${location.pathname === '/visit/detail' ? "active " : ''} `} to="/dashboard">*/}
                                        {/*    <svg className="nav_icon" width="24" height="24" role="img"><use xlinkHref="#svg_dashboard" /></svg>*/}
                                        {/*    <span className="nav_name">Dashboard</span>*/}
                                        {/*</Link>*/}
                                        {/*<Link onClick={() => setIsNavCollapsed(true)} className={`nav_link ${CheckPath("/profile")}`} to="/profile">*/}
                                        {/*    <svg className="nav_icon" width="24" height="24" role="img"><use xlinkHref="#svg_human" /></svg>*/}
                                        {/*    <span className="nav_name">Profile</span>*/}
                                        {/*</Link>*/}
                                        {/*<Link onClick={() => setIsNavCollapsed(true)} className={`nav_link ${CheckPath("/alldocuments")}`} to="/alldocuments">*/}
                                        {/*    <svg className="nav_icon" width="24" height="24" role="img"><use xlinkHref="#files_icon" /></svg>*/}
                                        {/*    <span className="nav_name">Documents</span>*/}
                                        {/*</Link>*/}
                                        {/*{(isGWF && devModules?.length > 0 ) && devModules?.map((list, index) => (*/}
                                        {/*    <Link onClick={() => setIsNavCollapsed(true)} key={index} className={`nav_link ${CheckPath(list?.urlPath)}`} to={list?.urlPath}>*/}
                                        {/*        <svg className="nav_icon" width="24" height="24" role="img"><use xlinkHref={list?.svgicon} /></svg>*/}
                                        {/*        <span className="nav_name">{list?.name}</span>*/}
                                        {/*    </Link>*/}
                                        {/*))}*/}

                                        <p onClick={logout} className="nav_link">
                                            <svg className="nav_icon" width="24" height="24" role="img"><use xlinkHref="#svg_exit" /></svg>
                                            <span className="nav_name">Logout</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-grow-1 m-3 content-container">
                                    {props.children}
                                </div>
                            </div>
                            : props.children}
                        {/*<div className={window.location.pathname.includes("/security") ? "col" : "col-md-8 col-xl-9 col-xxl-10"}>*/}

                        {/*</div>*/}

                    </div>
                    {!props.isViewer && <Footer account={account} />}
                </>
            )}
        </>
    );
}

export default Layout;
