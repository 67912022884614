import React, { useEffect, useState } from "react";
import { resourceConst } from "../../../services/constant/constant";
import { useLanguageRes } from "../../../services/Context/LanguageContext";
import { useManageResource } from "../../../services/Context/ManageResourceContext";
import DocumentAdd from "./DocumentAdd";
import DocumentList from "./Documentlist";

export default function Documents(props) {
    const [resourceAction, setResourceAction] = useState([]);
    const [addDocs, setAddDocs] = useState(false);
    const { getLanguageContent } = useLanguageRes();
    const { resourceList, getCurrentResourceAction, actionExists } = useManageResource();



    const toggleForm = () => {
        setAddDocs((prev) => !prev);
    }



    useEffect(() => {
        const _action = getCurrentResourceAction(props?.tabData?.code, props?.tabData?.parentId);
        setResourceAction(_action);
    }, [resourceList])

    // console.log(resourceAction);
    //console.log(props?.tabData);

    //appConst.cateogries.medicaldoc_type

    useEffect(() => {
        if (addDocs) {
            document.title = "Add documents";
        } else {
            document.title = "Documents";
        }
    }, [addDocs])


    // console.log(props?.tabData)
    // console.log(actionExists(resourceAction, resourceConst?.actions?.create))
    return (
        <>
            <div className={props?.encounter ? "card border-0" : ""}>
                <div className={props?.encounter ? "card-body" : ""}>
                    <div className="d-flex justify-content-between  mb-3">
                        <div>
                            <h5 className={props?.encounter ? "fs-6 card-title text-uppercase fw-bold" : ""}>{addDocs ? getLanguageContent("adddmnts") : getLanguageContent("docmnt")}</h5>

                        </div>
                        {(props?.encounterDetails?.statusId != 3 && props?.encounterDetails?.statusId != 4 && !addDocs && (props?.encounter ? actionExists(resourceAction, resourceConst?.actions?.create) && props?.tabData?.write : actionExists(resourceAction, resourceConst?.actions?.create))) && (
                            <div>
                                <button onClick={toggleForm} className="btn btn-primary text-nowrap">{getLanguageContent("addnw")}</button>
                            </div>
                        )}
                    </div>
                    <div>

                        {(props?.encounterDetails?.statusId != 3 && props?.encounterDetails?.statusId != 4 && !addDocs && (props?.encounter ? actionExists(resourceAction, resourceConst?.actions?.create) && props?.tabData?.write : actionExists(resourceAction, resourceConst?.actions?.create))) && (
                            <small className="text-secondary ">{getLanguageContent(`${addDocs ? "brwfls" : "clkadddmnts"}`)} </small>
                        )}
                    </div>
                    <div className={props?.encounter ? "" : "card border-0"}>
                        <div className={props?.encounter ? "" : "card-body"}>
                            {!addDocs && (<DocumentList
                                location={props?.location}
                                viewAction={actionExists(resourceAction, resourceConst?.actions?.view)}
                                deleteAction={actionExists(resourceAction, resourceConst?.actions?.delete)}
                                downloadAction={actionExists(resourceAction, resourceConst?.actions?.download)}
                            />
                            )}
                            {addDocs && (<DocumentAdd
                                toggleForm={toggleForm}
                                cateogries={props?.cateogries}
                                fileTypes={props?.fileTypes}
                                disText={props?.disText}
                                encounter={props?.encounter}
                                recordTypeId={props?.recordTypeId}
                                recordId={props?.recordId}
                                resource={resourceAction}
                            />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}