import React from "react";
import { appConst } from "../../../services/constant/constant";
import Documents from "../Documents/documents";

export default function PatientDocument() {
    const member_id = localStorage.getItem("memberId");

    const tabData = {
        code:"WU3"
    }

    return (
        <>
            <Documents
                cateogries={appConst.cateogries.medicaldoc_type}
                fileTypes={appConst.fileTypes.encounter.validationTypes}
                disText={appConst.fileTypes.encounter.displayText}
                recordTypeId={appConst.recordType.patientLevel}
                recordId={member_id}
                tabData={tabData}
                //encounter={false}
            />
        </>
    )
}